import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS5 = () => {
    const nombre = './assets/LincolnSquare.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Building Renovation</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Nestled in the vibrant heart of the city, The Copley condominium emerges as a beacon of modern luxury living. With its one-bedroom apartment bathed in natural light and expansive city views, this 750-square-foot sanctuary combines contemporary comfort with classic elegance. The updated kitchen and bathroom, complete with high-end finishes, represent just the start of a living experience that caters to those with a penchant for the finer things in life. Residents are welcomed into a spacious foyer that leads into a sun-drenched living area, boasting large windows that frame the captivating southwest cityscape. The hardwood floors guide you through a home that not only meets but exceeds the expectations of a discerning urbanite.</p>

                                        <p>
                                            This prestigious building is more than just an abode; it's an indulgence in a lifestyle that appreciates convenience at its doorstep. With Lincoln Center, Central Park, and a plethora of dining and shopping options within a stone's throw, The Copley stands as a testament to accessible grandeur. Living here means being at the crossroads of cultural richness and metropolitan bustle, where every day brings with it the promise of new adventures and comforts.
                                        </p>

                                        <Video videoLink="https://www.youtube.com/embed/e_G-W82lctE" bg='images/services/RS5_video_img.jpg' />

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>Each apartment features modernized kitchens and baths, boasting granite countertops, stainless steel appliances, and designer fixtures, blending functionality with style.</li>
                                            <li>The polished hardwood floors throughout the living spaces add warmth and sophistication, enhancing the timeless charm of each unit.</li>
                                            <li>Custom built-in closets provide generous storage, maintaining a sleek and organized living environment.</li>
                                            <li>Large windows offer not just natural light but also stunning views of the city, providing a picturesque backdrop to everyday life.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS12_outdoor.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS12_jacuzzi.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            From the moment of arrival, The Copley extends an experience that’s synonymous with luxury. The attentive full-service staff ensures that residents’ needs are not just met, but anticipated, creating an atmosphere of seamless comfort. The building’s management prides itself on maintaining an immaculate environment, with common areas that are meticulously cared for and services that are delivered with the utmost professionalism. Whether it’s the indoor pool for a morning swim, the health club for a rejuvenating workout, or the sauna to unwind after a busy day, the amenities at The Copley are designed to pamper.
                                        </p>
                                        <p>The communal outdoor garden and roof deck serve as serene oases amidst the city's bustle, offering spaces for relaxation and socialization. The children's playroom and laundry facilities add convenience for families, while the on-site garage provides a coveted city luxury: stress-free parking. For those who seek tranquility in the heart of the city, The Copley offers a refuge that embodies both the vibrancy of urban living and the peacefulness of a private retreat.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The thoughtful layout of each unit at The Copley maximizes space and light, ensuring a fluid living experience that harmonizes with the building's overall aesthetic of understated elegance.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Employing advanced building techniques, The Copley incorporates energy-efficient windows and state-of-the-art insulation, reflecting a commitment to sustainability and resident comfort.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Envisioned to offer a retreat in the city, The Copley's design pays homage to the area's rich cultural landscape while providing a sanctuary of luxury for its inhabitants.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The Copley stands as a shining example of upscale urban development, demonstrating that thoughtful design and strategic amenities can create high-demand residences in the heart of the city.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            The Copley isn’t just a place to live; it’s a statement—a declaration of love for the dynamic life that only a city like New York can offer, coupled with an unwavering commitment to luxury and ease. It promises not only a prestigious address but also a community and a lifestyle that can’t be replicated, making it an invaluable gem in the real estate crown of the city.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>Living at The Copley has redefined my standard for luxury and convenience. With the city's best cultural attractions at my doorstep and an array of amenities that cater to every need, my home is a sanctuary of tranquility amidst the vibrant rhythm of urban life. The Copley doesn’t just house my life; it enhances it in every way imaginable.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS5;