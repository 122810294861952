import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS13 = () => {
    const nombre = './assets/StuyvesantHeights.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Stuyvesant Heights</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Stepping off the A train, you're not just walking into any Brooklyn brownstone; you're entering a slice of New York history on one of Stuyvesant Heights’ most cherished blocks. Awarded for its verdant charm, this neighborhood boasts a residence that exemplifies the grandeur of late 19th-century architecture. The chateauesque-style limestone facade, crafted by the hands of historic developer Daniel Brooks Norris, presents a stately welcome with its intricate foliated carvings, delicate colonnettes, and sculpted familial crests.</p>

                                        <p>
                                            Built in 1892, this four-story, dual-family home reverently preserves the grandiose lifestyle of its era. Beyond the ornate entryway, the home unfolds like a period novel, each room a chapter dense with narrative—original carved doors that whisper tales of gatherings, the mirrored pier standing guard in the parlor, and fireplaces that have crackled through countless winters. Stained glass windows paint the interiors with colored light, and wainscoting lines the walls with the craftsmanship of yesteryears. This property doesn’t just offer a living space; it bestows a piece of artistry to be lived in and appreciated.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>Each of the residence's multiple fireplaces is a testament to its era, with mantles that speak to the bespoke craftsmanship of their time. Imagine the warm glow of a fire illuminating the intricately carved wood and tiles, reviving the spirit of historic Brooklyn evenings.</li>
                                            <li>The stained glass windows serve as colorful conduits to the past, filtering light through patterns that tell stories. These delicate pieces offer not just privacy but a permanent installation of art that changes with the day's mood.</li>
                                            <li>The heart of the home, the parlor, boasts an original pier mirror reflecting the opulence of a bygone era. It’s where the lavish woodwork meets the dignified heights of the ceilings, creating a space that's both grand and inviting.</li>
                                            <li>Running along the residence’s walls, the richly paneled wainscoting adds depth and character to each room, anchoring the legacy of its craftsmen in every fiber and groove.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS16_bath.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS16_back.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Assessment and Preservation: Our initial steps are to assess the integrity of the home's venerable features, ensuring the wainscoting, fireplace mantles, and woodwork are not only preserved but showcased, merging historical reverence with modern living standards.
                                        </p>
                                        <p>We move forward with careful enhancements, from updating the kitchen and bathrooms to integrating smart home technologies, all while honoring the original aesthetic and architectural significance.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Delve into the intricate layout, discovering how the generous proportions lend themselves to versatile living arrangements, from the owner’s luxurious duplex to the promising rental spaces.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Uncover the meticulous methods and materials used in the preservation efforts, showcasing the fusion of traditional techniques and contemporary advancements.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Explore the intentional decision-making process that respects the property’s heritage while ensuring it meets the expectations of today’s living standards.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Gain insights into the property’s investment potential, from the untapped rental income opportunities to the possibilities that its finished cellar and additional spaces present.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            This property is not merely a home but a custodian of history. It's a space where every nook echoes with stories of the past, yet whispers to the future. Owning this residence means preserving a chapter of Brooklyn's architectural anthology, ensuring that the elegance of 1892 remains a living, breathing part of New York's vibrant narrative.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>From the moment we stepped into this historic gem, we knew it was more than just a residence; it was a portal to an era of unrivaled craftsmanship. The original details, from the stained glass to the carved mantles, enveloped us in a sense of timelessness that’s rare in today's market. Living here is not just an experience; it's an honor to be part of this house's storied legacy. It's not just our home; it's our personal museum of a bygone age of elegance, now lovingly restored for modern living.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS13;