import React from 'react'
import { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'



//<img src="images/projects/RS1_property-view.webp" alt="blog" />

const Sliders = (props) => {

  const filejson = props.nombre;

  const [images2, setImages2] = useState([]); // array for properties images




  /*
  const images = [
    {
      original: "images/Real_Estate/1_CrownHeights_Brooklyn_1/1.living-kitchen.webp",
      thumbnail: "images/Real_Estate/1_CrownHeights_Brooklyn_1/1.living-kitchen.webp",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },


  ]
*/

  useEffect(() => {
    fetch(filejson)
      .then((response) => response.json())
      .then((data) => { setImages2(data) })
  }, [])




  return (
    <div style={{ width: "55vw", margin: "auto" }}>
      <ImageGallery items={images2}
        showPlayButton={false}
        showFullscreenButton={false}
        showThumbnails={true}
        showBullets={true}
        showNav={true}
        autoPlay={true}
        slideDuration={1000}
        slideInterval={3000}
      />

    </div>
  )
}

export default Sliders
