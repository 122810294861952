import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS18 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS18';

const ProjectDetailsRS18 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Bedford Stuyvesant Brooklyn' />

            <ProjectDetailsContainerRS18 />

        </>
    );
};

export default ProjectDetailsRS18;