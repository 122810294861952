import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS15 = () => {
    const nombre = './assets/Brownstone.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Brownstone</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Nestled within the storied embrace of Stuyvesant Heights, this resplendent chateauesque-style limestone rowhouse exudes a regal charisma that whispers tales of late 19th-century grandeur. A masterpiece of architectural heritage, its façade is a canvas of intricately carved foliage, elegant colonnettes, and noble crests that demand admiration. Step inside to discover a sanctuary where the romance of yesteryears harmoniously collides with the conveniences of modern comfort, a rare convergence of time-honored splendor and contemporary elegance.</p>

                                        <p>
                                            As one traverses the threshold, the opulent aura of the residence's grand living space becomes palpable. High ceilings crowned with delicate moldings pay homage to the sky, while the richly stained glass refracts daylight into a kaleidoscope of warmth throughout the abode. The original fireplace mantels serve as sentinels of history, their timeless presence a centerpiece for familial gatherings, stories, and laughter. This home is not just a dwelling but a testament to the enduring beauty of heritage Brooklyn, meticulously preserved and lovingly curated for the modern connoisseur of fine living.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>From the ornate pier mirror that greets visitors with a silent fanfare to the gleaming wainscoting lining the elegant walls, each element within the home has been conserved with precision, ensuring that the essence of its storied past continues to thrive.</li>
                                            <li>The vibrancy of the stained glass windows and the warm embrace of the cast iron wood burning stove converge, creating a tapestry of historic opulence and modern functionality that speaks to both the eyes and the soul.</li>
                                            <li>Masterfully carved doors open like portals to eras past, leading to rooms that boast multiple pocket doors and built-in cabinetry, each detail a curated blend of utility and Victorian artistry.</li>
                                            <li>Every fireplace mantle whispers tales of historical soirees, now standing as a testament to the property's rich narrative, further enhanced by the luxurious touch of the property’s ample and adaptable living spaces.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS18_dining-room-2.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS18_kitchen1-2.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            To inhabit this dwelling is to partake in a living legacy, and our dedicated service ensures your transition into this historical gem is as seamless as the flowing lines of its intricate woodwork. Our team approaches each phase with meticulous care, from initial interest to the moment you turn the key in the intricately carved doors, ensuring that the majesty of the home is matched only by the excellence of your experience.
                                        </p>
                                        <p>The stewardship of such a prestigious residence is an honor we hold with the highest regard. Our experts, versed in the nuanced art of historical preservation and modern enhancement, will guide you through the layers of legacy and luxury that this home presents, providing comprehensive support and advice tailored to the uniqueness of this property and your individual aspirations.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The sprawling layouts of each floor unfold like a storybook of architectural splendor. The owner’s duplex is a masterclass in spatial opulence, while the garden apartment's intelligent design promises both privacy and profitability. Every space within has been conceived with purpose, offering versatility to either lavish owner occupancy or advantageous rental possibilities, underpinned by the solid narrative of historical integrity and modern functionality.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Underneath the historical charm lies a foundation of advanced construction technology. From upgraded plumbing and electrical systems to the robust new roof carrying a 15-year warranty, the home's infrastructure harmonizes the integrity of original craftsmanship with the assurance of contemporary standards, ensuring peace of mind and a secure investment.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The philosophy guiding the home's restoration was one of reverence and vision. Recognizing the architectural sanctity of this historical abode, our craftsmen and designers sought to preserve the past while embracing the future, ensuring each room offers not just a slice of history but a canvas for future memories.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    This property stands as a beacon of successful real estate development, balancing historical preservation with modern market demands. It is a case study in enhancing value through sensitive restoration, highlighting the potential of a property that respects its past while offering contemporary allure.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            This property represents a nexus of historical reverence and modern living, a rare find that offers not just a home but a heritage. It is a promise of a lifestyle steeped in culture, comfort, and continuity, poised to continue its legacy into the future.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>Embarking on our journey with this brownstone has been an enchanting experience. Each day unfolds like a page from a well-loved novel, rich with history and brimming with contemporary comfort. The intricate details, from the parlor's pier mirror to the original shutters, evoke a sense of wonder that only a home with such a storied past could offer. The seamless melding of its historic charm with modern amenities has truly transformed our daily living into an elevated experience. The dedication and thoughtful guidance we received from the team were unparalleled, making our transition into this majestic residence not just seamless but truly enjoyable.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS15;