import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS12 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS12';

const ProjectDetailsRS12 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Midtown East NY' />

            <ProjectDetailsContainerRS12 />

        </>
    );
};

export default ProjectDetailsRS12;