import Isotope from 'isotope-layout';
import React, { useEffect, useState } from 'react';
import ProjectCard02 from '../../Components/ProjectElements/ProjectCard02';

const ProjectHome01 = () => {
    const ProjectHomeData01 = [
        {
            id: '1',
            img: 'images/projects/RealEstate_CrownHeightsBrooklyn_sold.png',
            tag: 'realestate',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-1-CrownHeights'
        },
        {
            id: '2',
            img: 'images/projects/RS2_EastFlatbush_sold.png',
            tag: 'realestate',
            title: 'East Flatbush Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-2-EastFlatbush'
        },
        {
            id: '3',
            img: 'images/projects/frontCrownHeights-186_sold.png',
            tag: 'realestate',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-3-CrownHeights'
        },
        {
            id: '4',
            img: 'images/projects/RS4_Front_CH_card_sold.png',
            tag: 'realestate',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-4-CrownHeights'
        },
        {
            id: '5',
            img: 'images/projects/RS12_lobby_card_sold.png',
            tag: 'realestate',
            title: 'Lincoln Square NY',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-5-LincolnSquare'
        },
        {
            id: '6',
            img: 'images/projects/RealEstate_Bedfordnew_sold.png',
            tag: 'realestate',
            title: 'Bedford Stuyvesant Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-6-BedfordStuyvesant'
        },
        {
            id: '7',
            img: 'images/projects/RealEstate_Bedford2new_sold.png',
            tag: 'realestate',
            title: 'Bedford Stuyvesant Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-7-BedfordStuyvesant'
        },
        {
            id: '8',
            img: 'images/projects/RS6_front_card_sold.png',
            tag: 'realestate',
            title: 'Bedford Stuyvesant Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-8-BedfordStuyvesant'
        },
        {
            id: '9',
            img: 'images/projects/RS10_bayridgefront_card_sold.png',
            tag: 'realestate',
            title: 'Bay Ridge Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-9-MidtwonEast'
        },
        {
            id: '10',
            img: 'images/projects/RS11_front_card_sold.png',
            tag: 'realestate',
            title: 'Midtown East NY',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-10-LincolnSquare'
        },
        {
            id: '11',
            img: 'images/projects/RS14_front_card_sold.png',
            tag: 'realestate',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-RealEstate-11-CrownHeights'
        },
        {
            id: '12',
            img: 'images/projects/RS15_building_card_sold.png',
            tag: 'realestate',
            title: 'Midtown East NY',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-12-MidtownEast'
        },
        {
            id: '13',
            img: 'images/projects/RS16_front_card_sold.png',
            tag: 'realestate',
            title: 'Stuyvesant Heights Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-13-StuyvesantHeights'
        },
        {
            id: '14',
            img: 'images/projects/RS17_front_card_sold.png',
            tag: 'realestate',
            title: 'Stuyvesant Heights Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-14-StuyvesantHeights'
        },
        {
            id: '15',
            img: 'images/projects/RS18_front_card_sold.png',
            tag: 'realestate',
            title: 'Brownstone Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-15-Brownstone'
        },
        {
            id: '16',
            img: 'images/projects/RS19_exterior_card_sold.png',
            tag: 'realestate',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-16-CrownHeghts'
        },
        {
            id: '17',
            img: 'images/projects/RS22_front_card_sold.png',
            tag: 'realestate',
            title: 'Ditmas Park Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-17-DitmasPark'
        },
        {
            id: '18',
            img: 'images/projects/RS23_exterior_card_sold.png',
            tag: 'realestate',
            title: 'Bedford Stuyvesant Brooklyn',
            city: 'NEW YORK',
            route:'/project-details-RealEstate-18-BedfordStuyvesant'
        },
        {
            id: '19',
            img: 'images/projects/RS20_front_card_sold.png',
            tag: 'realestate',
            title: 'Stuyvesant Heights Brooklyn',
            city: 'NEW YORK',
        },
        {
            id: '20',
            img: 'images/projects/RS21_front_card_sold.png',
            tag: 'realestate',
            title: 'Stuyvesant Heights Brooklyn',
            city: 'NEW YORK',
        },
        {
            id: '21',
            img: 'images/projects/Construction_Pharmacymain.jpg',
            tag: 'construction business',
            title: 'Pharmacy',
            city: 'NEW YORK',
            route: '/project-details-pharm'
        },
        {
            id: '22',
            img: 'images/projects/Construction_Housemain.jpg',
            tag: 'construction',
            title: 'Great Neck House',
            city: 'NEW YORK',
            route: '/project-details-Const-GratNeck'
        },
        {
            id: '23',
            img: 'images/projects/Construction_Buildingmain.jpg',
            tag: 'construction',
            title: 'Crown Heights Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-ConstCrownHeights'
        },
        {
            id: '31',
            img: 'images/projects/Whitestone_FrontCard.png',
            tag: 'construction',
            title: 'Whitestone Queens',
            city: 'NEW YORK',
            route: '/project-details-Const-Whitestone'
        },
        {
            id: '32',
            img: 'images/projects/regopark_FrontCard.png',
            tag: 'construction',
            title: 'Rego Park Queens',
            city: 'NEW YORK',
            route: '/project-details-Const-RegoPark'
        },
        {
            id: '33',
            img: 'images/projects/HarringtonAve_Bronx_FrontCard.png',
            tag: 'construction',
            title: 'Harrington ave Bronx',
            city: 'NEW YORK',
            route: '/project-details-Const-Harrington'
        },
        {
            id: '34',
            img: 'images/projects/Herkimer_Brooklyn_FrontCard.png',
            tag: 'construction',
            title: 'Herkimer St Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-Const-Herkimer'
        },
        {
            id: '35',
            img: 'images/projects/Flatbushave_Brooklyn_FrontCard.png',
            tag: 'construction',
            title: 'FlatbushAve Brooklyn',
            city: 'NEW YORK',
            route: '/project-details-Const-Flatbush'
        },

    ];

    const [filterKey, setFilterKey] = useState('*')

    useEffect(() => {
        Isotope.current = new Isotope('.grid', {
            itemSelector: '.element-item',
            percentPosition: true,
            // layoutMode: 'packery',
            // masonry: {
            //     columnWidth: '.grid-sizer'
            // },
            transformsEnabled: true,
            transitionDuration: "700ms",
            resize: true,
            fitWidth: true,
            // columnWidth: '.grid-sizer',
        })

        // cleanup
        return () => Isotope.current.destroy()
    }, []);

    // handling filter key change
    useEffect(() => {
        filterKey === '*'
            ? Isotope.current.arrange({ filter: `*` })
            : Isotope.current.arrange({ filter: `.${filterKey}` })
    }, [filterKey]);

    const handleFilterKeyChange = key => () => setFilterKey(key);

    return (
        <div className="section project_iso project_iso1">
            <div className="container-fluid g-0">
                <div className="section_header text-center">
                    <div className="shadow_icon"><img src="images/about/shadow_icon1.png" alt="" /></div>
                    <h6 className="section_sub_title">ABOUT Skyline City Team CONSTRUCTION</h6>
                    <h1 className="section_title">Our Most Popular Projects</h1>
                </div>
                <div className="row g-0">
                    <div className="col">
                        {/* <Tabs>
                            <TabList className="button-group filters-button-group">
                                <Tab className="button is-checked" data-filter="*">All</Tab>
                                <Tab className="button" data-filter=".commercial">Commercial</Tab>
                                <Tab className="button" data-filter=".highrise">Highrise</Tab>
                                <Tab className="button" data-filter=".residential">Residential</Tab>
                                <Tab className="button" data-filter=".business">Business</Tab>
                            </TabList>


                            <div className="grid grid-5">
                                <TabPanel>
                                    <div className="element-item highrise">
                                        <div className="teambox">
                                            <img src="images/team/Project_2_Images/3.png" alt="" />
                                            <div className="teambox_inner">
                                                <div className="team_social">
                                                    <div className="share"><i className="ion-android-share-alt"></i></div>
                                                    <ul>
                                                        <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                                        <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                                        <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                                                        <li className="linkedin"><a href="#"><i className="ion-social-linkedin-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="teambox_intro">
                                                    <div className="team_flex">
                                                        <h6>NEW YORK</h6>
                                                        <h5><a href="/project-details">Multistored Building</a></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="element-item business highrise">
                                        <div className="teambox">
                                            <img src="images/team/Project_2_Images/4.png" alt="" />
                                            <div className="teambox_inner">
                                                <div className="team_social">
                                                    <div className="share"><i className="ion-android-share-alt"></i></div>
                                                    <ul>
                                                        <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                                        <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                                        <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                                                        <li className="linkedin"><a href="#"><i className="ion-social-linkedin-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="teambox_intro">
                                                    <div className="team_flex">
                                                        <h6>NEW YORK</h6>
                                                        <h5><a href="/project-details">Highrise Architecture</a></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="element-item residential ">
                                        <div className="teambox">
                                            <img src="images/team/Project_2_Images/5.png" alt="" />
                                            <div className="teambox_inner">
                                                <div className="team_social">
                                                    <div className="share"><i className="ion-android-share-alt"></i></div>
                                                    <ul>
                                                        <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                                        <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                                        <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                                                        <li className="linkedin"><a href="#"><i className="ion-social-linkedin-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="teambox_intro">
                                                    <div className="team_flex">
                                                        <h6>NEW YORK</h6>
                                                        <h5><a href="/project-details">Large Swiming Pool</a></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="element-item commercial">
                                        <div className="teambox">
                                            <img src="images/team/Project_2_Images/1.png" alt="" />
                                            <div className="teambox_inner">
                                                <div className="team_social">
                                                    <div className="share"><i className="ion-android-share-alt"></i></div>
                                                    <ul>
                                                        <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                                        <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                                        <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                                                        <li className="linkedin"><a href="#"><i className="ion-social-linkedin-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="teambox_intro">
                                                    <div className="team_flex">
                                                        <h6>NEW YORK</h6>
                                                        <h5><a href="/project-details">Large City Tower</a></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="element-item residential">
                                        <div className="teambox">
                                            <img src="images/team/Project_2_Images/2.png" alt="" />
                                            <div className="teambox_inner">
                                                <div className="team_social">
                                                    <div className="share"><i className="ion-android-share-alt"></i></div>
                                                    <ul>
                                                        <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                                        <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                                        <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                                                        <li className="linkedin"><a href="#"><i className="ion-social-linkedin-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="teambox_intro">
                                                    <div className="team_flex">
                                                        <h6>NEW YORK</h6>
                                                        <h5><a href="/project-details">Curve Sky Tower</a></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>

                            </div>


                        </Tabs> */}

                        <div className="button-group filters-button-group">
                            <button className="button" onClick={handleFilterKeyChange('*')}>All <sup className="filter-count"></sup></button>
                            <button className="button" onClick={handleFilterKeyChange('realestate')}>Real Estate <sup className="filter-count"></sup></button>
                            <button className="button" onClick={handleFilterKeyChange('construction')}>Construction <sup className="filter-count"></sup></button>
                            <button className="button" onClick={handleFilterKeyChange('business')}>Business <sup className="filter-count"></sup></button>

                        </div>

                        <div class="grid grid-5">
                            {
                                ProjectHomeData01.map(data => <ProjectCard02
                                    key={data.id}
                                    data={data}
                                />)
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectHome01;