import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerConstHarrington from '../../Containers/ProjectContainers/ProjectDetailsContainerConstHarrington';

const ProjectDetailsConstHarrington = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='House Renovation' />

            <ProjectDetailsContainerConstHarrington/>

        </>
    );
};



export default ProjectDetailsConstHarrington;


