import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerConstGreatNeck from '../../Containers/ProjectContainers/ProjectDetailsContainerConstGreatNeck';

const ProjectDetailsConstGreatneck = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='House Renovation' />

            <ProjectDetailsContainerConstGreatNeck />

        </>
    );
};



export default ProjectDetailsConstGreatneck;





/*
ProjectDetailsReno2 ->ProjectDetailsConstGreatneck
ProjectDetailsContainerConstGreatNeck -> 

*/
