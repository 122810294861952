import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS3 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS3';

const ProjectDetailsRS3 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='CROWN HEIGHTS' />

            <ProjectDetailsContainerRS3 />

        </>
    );
};

export default ProjectDetailsRS3;