import React from 'react';

const Experience = () => {
    const headerData = {
        img: 'images/about/shadow_icon1.png',
        heading: 'Pioneering the Future of Construction with Skyline City Team',
        subheading: 'ABOUT Skyline City Team CONSTRUCTION',
    };
    

    return (
        <div className="experience section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="group_image_holder type_1">
                            <div className="expe_box">
                                <div className="expe_box_inner">
                                    <h1>15</h1>
                                    Years of Experience
                                </div>
                            </div>
                            <div className="image_object">
                                <img src="images/about/Exp_3.png" alt="" />
                                <div className="object">
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/s1.png" alt="About" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="experience_content">
                            <div className="section_header">
                                <div className="shadow_icon"><img src={headerData.img} alt="" /></div>
                                <h6 className="section_sub_title">{headerData.subheading}</h6>
                                <h1 className="section_title">{headerData.heading}</h1>
                                <p className="section_desc">At Skyline City Team, we're not just builders; we're visionaries in Real Estate and Construction. With 15 years of top-notch work, we stand as New York's go-to for creating not just buildings, but lasting legacies. We're known for being safe, reliable, and budget-friendly. Let us turn your visions into reality.</p>
                            </div>
                            <a className="button" href="/about">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;