import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS2 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS2';

const ProjectDetailsRS2 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='East Flatbush' />

            <ProjectDetailsContainerRS2 />

        </>
    );
};

export default ProjectDetailsRS2;