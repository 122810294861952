import { createBrowserRouter } from 'react-router-dom';

// Home Layouts
import HomeLayout from '../Layouts/HomeLayout';
import About from '../Pages/About';
import Blog01 from '../Pages/Blogs/Blog01';
import Blog02 from '../Pages/Blogs/Blog02';
import ContactPage from '../Pages/ContactPage';
import FAQ from '../Pages/FAQ';
import Home01 from '../Pages/Homes/Home01';

// Construction Projects Routes
import ProjectDetails from '../Pages/Projects/ProjectDetails';
import ProjectDetailsPharm from '../Pages/Projects/ProjectDetailsPharm';
import ProjectDetailsReno1 from '../Pages/Projects/ProjectDetailsConstCrownH';
import ProjectDetailsConstGreatneck from '../Pages/Projects/ProjectDetailsConstGreatneck';
import ProjectDetailsConstHarrington from '../Pages/Projects/ProjectDetailsConstHarrington';
import ProjectDetailsConstWhitestone from '../Pages/Projects/ProjectDetailsConstWhitestone';
import ProjectDetailsConstRegopark from '../Pages/Projects/ProjectDetailsConstRegopark';
import ProjectDetailsConstHerkimer from '../Pages/Projects/ProjectDetailsConstHerkimer';
import ProjectDetailsConstFlatbush from '../Pages/Projects/ProjectDetailsConstFlatbush';





//Real Estate Project Routes
import ProjectDetailsRS1 from '../Pages/Projects/ProjectDetailsRS1';
import ProjectDetailsRS2 from '../Pages/Projects/ProjectDetailsRS2';
import ProjectDetailsRS3 from '../Pages/Projects/ProjectDetailsRS3';
import ProjectDetailsRS4 from '../Pages/Projects/ProjectDetailsRS4';
import ProjectDetailsRS5 from '../Pages/Projects/ProjectDetailsRS5';
import ProjectDetailsRS6 from '../Pages/Projects/ProjectDetailsRS6';
import ProjectDetailsRS7 from '../Pages/Projects/ProjectDetailsRS7';
import ProjectDetailsRS8 from '../Pages/Projects/ProjectDetailsRS8';
import ProjectDetailsRS9 from '../Pages/Projects/ProjectDetailsRS9';
import ProjectDetailsRS10 from '../Pages/Projects/ProjectDetailsRS10';
import ProjectDetailsRS11 from '../Pages/Projects/ProjectDetailsRS11';
import ProjectDetailsRS12 from '../Pages/Projects/ProjectDetailsRS12';
import ProjectDetailsRS13 from '../Pages/Projects/ProjectDetailsRS13';
import ProjectDetailsRS14 from '../Pages/Projects/ProjectDetailsRS14';
import ProjectDetailsRS15 from '../Pages/Projects/ProjectDetailsRS15';
import ProjectDetailsRS16 from '../Pages/Projects/ProjectDetailsRS16';
import ProjectDetailsRS17 from '../Pages/Projects/ProjectDetailsRS17';
import ProjectDetailsRS18 from '../Pages/Projects/ProjectDetailsRS18';



import Projects01 from '../Pages/Projects/Projects01';
import Projects02 from '../Pages/Projects/Projects02';


import ServiceDetailsCommercial from '../Pages/Services/ServiceDetailsCommercial';
import ServiceDetailsRealEstate from '../Pages/Services/ServiceDetailsRealEstate';
import ServiceContainerDemolition from '../Pages/Services/ServiceDetailsDemolition';
import ServiceContainerElectrical from '../Pages/Services/ServiceDetailsElectrical';
import ServiceContainerPlumbing from '../Pages/Services/ServiceDetailsPlumbing';
import ServiceContainerExteriorWork from '../Pages/Services/ServiceDetailsExteriorWork';
import ServiceContainerConsulting from '../Pages/Services/ServiceDetailsConsulting';
import ServiceContainer3DRendering from '../Pages/Services/ServiceDetails3DRendering';
import ServiceContainerHVAC from '../Pages/Services/ServiceDetailsHVAC';




import Services01 from '../Pages/Services/Services01';
import Services02 from '../Pages/Services/Services02';
import TeamPage from '../Pages/TeamPage';

// Home Layouts02
import HomeLayout02 from '../Layouts/HomeLayout02';
import Home02 from '../Pages/Homes/Home02';

// Shop Layouts
import ShopLayout from '../Layouts/ShopLayout';
import ProductDetails from '../Pages/Shops/ProductDetails';
import Shop01 from '../Pages/Shops/Shop01';
import Shop02 from '../Pages/Shops/Shop02';
import Shop03 from '../Pages/Shops/Shop03';
import ShopCart from '../Pages/Shops/ShopCart';
import ShopCheckout from '../Pages/Shops/ShopCheckout';

// Shared Layouts
import BlogDetails from '../Pages/Blogs/BlogDetails';
import BlogDetails02 from '../Pages/Blogs/BlogDetails02'
import BlogDetails03 from '../Pages/Blogs/BlogDetails03'
import BlogDetails04 from '../Pages/Blogs/BlogDetails04'
import BlogDetails05 from '../Pages/Blogs/BlogDetails05'
import BlogDetails06 from '../Pages/Blogs/BlogDetails06'
import BlogDetails07 from '../Pages/Blogs/BlogDetails07'
import BlogDetails08 from '../Pages/Blogs/BlogDetails08'
import BlogDetails09 from '../Pages/Blogs/BlogDetails09'

import ErrorPage from '../Pages/ErrorPage/ErrorPage';

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>
            },
            {
                path: '/project',
                element: <Projects01/>
            },
            {
                path: '/project-2',
                element: <Projects02/>
            },
            {
                path: '/project-details',
                element: <ProjectDetails/>
            },
            {
                path: '/project-details-pharm',
                element: <ProjectDetailsPharm/>
            },
            {
                path: '/project-details-ConstCrownHeights',
                element: <ProjectDetailsReno1/>
            },
            {
                path: '/project-details-Const-GratNeck',
                element: <ProjectDetailsConstGreatneck/>
            },
            {
                path: '/project-details-Const-Whitestone',
                element: <ProjectDetailsConstWhitestone/>
            },
            {
                path: '/project-details-Const-RegoPark',
                element: <ProjectDetailsConstRegopark/>
            },
            {
                path: '/project-details-Const-Harrington',
                element: <ProjectDetailsConstHarrington/>
            },
            {
                path: '/project-details-Const-Herkimer',
                element: <ProjectDetailsConstHerkimer/>
            },
            {
                path: '/project-details-Const-Flatbush',
                element: <ProjectDetailsConstFlatbush/>
            },
            {
                path: '/project-details-RealEstate-1-CrownHeights',
                element: <ProjectDetailsRS1/>
            },
            {
                path: '/project-details-RealEstate-2-EastFlatbush',
                element: <ProjectDetailsRS2/>
            },
            {
                path: '/project-details-RealEstate-3-CrownHeights',
                element: <ProjectDetailsRS3/>
            },
            {
                path: '/project-details-RealEstate-4-CrownHeights',
                element: <ProjectDetailsRS4/>
            },
            {
                path: '/project-details-RealEstate-5-LincolnSquare',
                element: <ProjectDetailsRS5/>
            },
            {
                path: '/project-details-RealEstate-6-BedfordStuyvesant',
                element: <ProjectDetailsRS6/>
            },
            {
                path: '/project-details-RealEstate-7-BedfordStuyvesant',
                element: <ProjectDetailsRS7/>
            },
            {
                path: '/project-details-RealEstate-8-BedfordStuyvesant',
                element: <ProjectDetailsRS8/>
            },
            {
                path: '/project-details-RealEstate-9-MidtwonEast',
                element: <ProjectDetailsRS9/>
            },
            {
                path: '/project-details-RealEstate-10-LincolnSquare',
                element: <ProjectDetailsRS10/>
            },
            {
                path: '/project-details-RealEstate-11-CrownHeights',
                element: <ProjectDetailsRS11/>
            },
            {
                path: '/project-details-RealEstate-12-MidtownEast',
                element: <ProjectDetailsRS12/>
            },
            {
                path: '/project-details-RealEstate-13-StuyvesantHeights',
                element: <ProjectDetailsRS13/>
            },
            {
                path: '/project-details-RealEstate-14-StuyvesantHeights',
                element: <ProjectDetailsRS14/>
            },
            {
                path: '/project-details-RealEstate-15-Brownstone',
                element: <ProjectDetailsRS15/>
            },
            {
                path: '/project-details-RealEstate-16-CrownHeghts',
                element: <ProjectDetailsRS16/>
            },
            {
                path: '/project-details-RealEstate-17-DitmasPark',
                element: <ProjectDetailsRS17/>
            },
            {
                path: '/project-details-RealEstate-18-BedfordStuyvesant',
                element: <ProjectDetailsRS18/>
            },
            {
                path: '/services',
                element: <Services01/>
            },
            {
                path: '/services-2',
                element: <Services02/>
            },
            {
                path: '/service-details-Commercial',
                element: <ServiceDetailsCommercial/>
            },
            {
                path: '/service-details-RealEstate',
                element: <ServiceDetailsRealEstate/>
            },
            {
                path: '/service-details-Demolition',
                element: <ServiceContainerDemolition/>
            },
            {
                path: '/service-details-Electrical',
                element: <ServiceContainerElectrical/>
            },
            {
                path: '/service-details-Plumbing',
                element: <ServiceContainerPlumbing/>
            },
            {
                path: '/service-details-ExteriorWork',
                element: <ServiceContainerExteriorWork/>
            },
            {
                path: '/service-details-Consulting',
                element: <ServiceContainerConsulting/>
            },
            {
                path: '/service-details-3DRendering',
                element: <ServiceContainer3DRendering/>
            },
            {
                path: '/service-details-HVAC',
                element: <ServiceContainerHVAC/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/team',
                element: <TeamPage/>
            },
            {
                path: '/faq',
                element: <FAQ/>
            },
            {
                path: '/blog-1',
                element: <Blog01/>
            },
            {
                path: '/blog-2',
                element: <Blog02/>
            },
            {
                path: '/blog-details',
                element: <BlogDetails/>
            },
            {
                path: '/blog-details-2',
                element: <BlogDetails02/>
            },
            {
                path: '/blog-details-3',
                element: <BlogDetails03/>
            },
            {
                path: '/blog-details-4',
                element: <BlogDetails04/>
            },
            {
                path: '/blog-details-5',
                element: <BlogDetails05/>
            },
            {
                path: '/blog-details-6',
                element: <BlogDetails06/>
            },
            {
                path: '/blog-details-7',
                element: <BlogDetails07/>
            },
            {
                path: '/blog-details-8',
                element: <BlogDetails08/>
            },
            {
                path: '/blog-details-9',
                element: <BlogDetails09/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            }
        ]
    },
    {
        path: "/",
        element: <HomeLayout02/>,
        children: [
            {
                path: '/home-2',
                element: <Home02/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            }
        ]
    },
    {
        path: "/",
        element: <ShopLayout/>,
        children: [
            {
                path: '/shop-1',
                element: <Shop01/>
            },
            {
                path: '/shop-2',
                element: <Shop02/>
            },
            {
                path: '/shop-3',
                element: <Shop03/>
            },
            // {
            //     path: '/shop-4',
            //     element: <Shop04/>
            // },
            {
                path: '/product',
                element: <ProductDetails/>
            },
            {
                path: '/cart',
                element: <ShopCart/>
            },
            {
                path: '/checkout',
                element: <ShopCheckout/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            }
        ]
    },
])

