import React from 'react';
import AboutBelowContent02 from '../../Components/ExperienceBlocks/AboutBelowContent02';

const ExperienceAbout = () => {
    const headerData = {
        heading: 'Turning Visions into Landmarks',
        subheading: 'ABOUT Skyline City Team CONSTRUCTION',
    };

    const ExpericenData = [
        {
            id: '1',
            title: 'Our Mission',
            description: 'To deliver construction solutions where quality meets efficiency. Skyline City Team stands as a beacon of dependability, providing services that bring your project to completion on time, within budget, and above expectations.',
            img: 'images/about/t1.png'

        },
        {
            id: '2',
            title: 'Our Vision',
            description: 'To redefine the skyline of New York with structures that embody architectural integrity and innovation. We envision a future where every construction is a landmark of our dedication to safe, affordable, and exceptional building practices.',
            img: 'images/about/t2.png'
        },
    ];
    return (
        <div className="experience">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="group_image_holder type_1">
                            <div className="expe_box">
                                <div className="expe_box_inner">
                                    <h1>15+</h1>
                                    Years of Experience
                                </div>
                            </div>
                            <img src="images/about/1.png" alt="" />
                            <div className="object">
                                <img src="images/about/3.png" alt="About" />
                                <img src="images/about/3.png" alt="About" />
                                <img src="images/about/3.png" alt="About" />
                                <img src="images/about/s1.png" alt="About" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="experience_content about">
                            <div className="section_header">
                                <h6 className="section_sub_title">{headerData.subheading}</h6>
                                <h1 className="section_title">{headerData.heading}</h1>
                                <p className="section_desc">With over 15 years of pioneering the construction landscape, Skyline City Team has become synonymous with trust, quality, and excellence. Our ethos revolves around transforming blueprints into remarkable realities, ensuring every project is a testament to our commitment to reliability, cost-efficiency, and uncompromised safety.</p>
                                <div className="about_below">
                                    {
                                        ExpericenData.map(data =>
                                            <AboutBelowContent02
                                                key={data.id}
                                                data={data}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <a className="button" href="/about">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExperienceAbout;