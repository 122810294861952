import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerConstCrownH from '../../Containers/ProjectContainers/ProjectDetailsContainerConstCrownH';

const ProjectDetailsConstCrownH = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Building Renovation' />

            <ProjectDetailsContainerConstCrownH />

        </>
    );
};

export default ProjectDetailsConstCrownH;

/*

ProjectDetailsReno1 -> ProjectDetailsConstCrownH

ProjectDetailsContainerReno1-> ProjectDetailsContainerConstCrownH

*/

