import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS4 = () => {
    const nombre = './assets/CrownHeights_BK3.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Building Renovation</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Nestled in the heart of the historic Crown Heights district, Dean Street stands as a testament to the grandeur of the Renaissance Revival style. Constructed in 1898, this 4-story brownstone marvels with its expansive spaces and meticulous craftsmanship. From the stately brownstone stoop, one steps into a world of yesteryear charm, greeted by an original, opulent staircase and an awe-inspiring parlor floor, adorned with dual majestic wood mantels.</p>

                                        <p>
                                            The residence is a symphony of period details, boasting waist-high wainscoting, intricate inlaid flooring, seven stunning wood mantels, bay windows with pocket shutters, and original doors set within elaborate framing. The facade of the building enchants with exterior ornaments, each telling a story of its 19th-century origins. The vast interior spans approximately 4,660 square feet, offering a mix of private and communal spaces suited for modern living while revering its historical roots.
                                        </p>

                                        <Video videoLink="https://www.youtube.com/embed/GnAo53bh8bg" bg='images/services/RS4_video_img.jpg' />

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>Authentic period details such as original parquet floors, molding, and wainscoting evoke the house’s historical grandeur.</li>
                                            <li>Oversized wood mantels and decorative fireplaces provide focal points of craftsmanship and elegance in the living spaces.</li>
                                            <li>Bay windows and pocket shutters blend functionality with the timeless beauty of the 19th-century design.</li>
                                            <li>The exterior’s ornamental stonework and stoop showcase the distinguished Renaissance Revival style that is both impressive and welcoming.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS8_masterbed.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS8_stairs.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Upon entering Dean Street, the service journey begins with an appreciation for the property's storied past and potential future. The expansive layout demands a creative vision to maximize the functionality and aesthetic of each unit, ensuring they align with the building’s historical significance and modern lifestyle needs. Careful planning and execution are paramount in preserving the intricate details while infusing contemporary comforts.
                                        </p>
                                        <p>The conversion of this brownstone into a personalized home or a multifamily investment requires a meticulous step-by-step approach. From assessing structural integrity to designing each living space, every phase is handled with expertise and sensitivity to the building's heritage. The goal is to enhance the living experience while honoring the architectural integrity that defines Dean Street.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Each floor of this brownstone unfolds like a historical narrative, with the garden apartment, the duplex, and the studios each offering distinct living experiences, enriched by the original architectural elements.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    While the brownstone’s façade and interiors reflect a bygone era, modern construction technologies are employed to ensure the integrity and longevity of both its aesthetic and structural elements.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The renovation of this brownstone is a thoughtful balance of preserving the nostalgic charm of the property while integrating modern amenities and finishes to appeal to contemporary tastes.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Innovative Solutions for Enhanced Pharmacy Operations</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The unique appeal of this property lies in its potential — a canvas for luxury rentals or condominiums, ripe for a transformation that respects its heritage and capitalizes on its prime location.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            Dean Street offers more than just a living space; it offers a piece of history, a masterpiece of architecture waiting to be re-envisioned. Its blend of historic charm and expansive space in a coveted neighborhood creates an unparalleled opportunity for those looking to immerse themselves in the essence of Crown Heights.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>We were captivated by the timeless elegance of 1154 Dean Street from the moment we laid eyes on its stunning façade. Living here has been a harmonious blend of historic splendor and modern convenience, a testament to the thoughtful restoration that breathes new life into this magnificent brownstone. It's not just our home; it's a living piece of art.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS4;