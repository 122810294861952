import React from 'react';
import ServiceCard02_01 from '../../Components/ServiceCards/ServiceCard02_01';
import PaginationDiv from '../../Components/TeamElements/PaginationDiv';

const ServiceContainer01 = () => {
    const ServiceData01 = [
        {
            id: '1',
            img: 'images/services/s4.png',
            img02: 'images/services/Service_Hover_images/1.jpg',
            tag: 'COMMERCIAL DESIGN',
            title: 'Skyline City Team: Pioneering Commercial Design with Safe, Reliable & Cost-Effective Construction Solutions.',
            route: '/service-details-Commercial'
        },
        {
            id: '2',
            img: 'images/services/s5.png',
            img02: 'images/services/Service_Hover_images/2.jpg',
            tag: 'REAL ESTATE',
            title: 'Skyline City Team: Elevating Real Estate with Award-Winning Construction Excellence.',
            route: '/service-details-RealEstate'
        },
        {
            id: '3',
            img: 'images/services/s7.png',
            img02: 'images/services/Service_Hover_images/3.jpg',
            tag: 'DEMOLITION',
            title: 'Skyline City Team: Mastering Demolition with Strategic Intelligence for Extraordinary Projects.',
            route: '/service-details-Demolition'
        },
        {
            id: '4',
            img: 'images/services/s6.png',
            img02: 'images/services/Service_Hover_images/4.jpg',
            tag: 'ELECTRICAL',
            title: 'Skyline City Team: Powering Progress with Safe, Reliable & Cost-Effective Electrical Solutions.',
            route: '/service-details-Electrical'
        },
        {
            id: '5',
            img: 'images/services/s4.png',
            img02: 'images/services/Service_Hover_images/5.jpg',
            tag: 'PLUMBING',
            title: 'Skyline City Team: Leading the Flow with Premier Plumbing Services for Comprehensive Projects.',
            route: '/service-details-Plumbing'
        },
        {
            id: '6',
            img: 'images/services/s9.png',
            img02: 'images/services/Service_Hover_images/6.jpg',
            tag: 'EXTERIOR WORK',
            title: 'Skyline City Team: Expertise in Exterior Work - Mastering Breakpoints, Stucco, Foundation Bricklaying, Limestone, Cement Work, and Roofing Excellence.',
            route: '/service-details-ExteriorWork'
        },
        {
            id: '7',
            img: 'images/services/s11.png',
            img02: 'images/services/Service_Hover_images/8.jpg',
            tag: '3D RENDERING',
            title: 'Skyline City Team: Revolutionizing Construction with Award-Winning 3D Rendering Capabilities.',
            route: '/service-details-3DRendering'
        },
        {
            id: '8',
            img: 'images/services/s4.png',
            img02: 'images/services/Service_Hover_images/5.jpg',
            tag: 'CONSULTING',
            title: 'Skyline City Team: Navigating Extraordinary Projects with Strategic Consulting for Buyers, Renters, and Sellers.',
            route: '/service-details-Consulting'
        },
        {
            id: '9',
            img: 'images/services/s12.png',
            img02: 'images/services/Service_Hover_images/9.jpg',
            tag: 'HVAC',
            title: 'Skyline City Team: Leading Climate Control Innovations with Proud Partnerships in HVAC Excellence.',
            route: '/service-details-HVAC'
        },
    ];

    return (
        <div className="main_wrapper">
            <div className="section service services_inner_page">
                <div className="container">
                    <div className="row">
                        {
                            ServiceData01.map(data =>
                                <ServiceCard02_01
                                    key={data.id}
                                    data={data}
                                />
                            )
                        }

                        <PaginationDiv />

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceContainer01;