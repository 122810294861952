import $ from 'jquery';
import React, { useEffect } from 'react';
import PageHeader from '../Components/common/PageHeader';
import Banner from '../Containers/Banner';

const FAQ = () => {
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);


    return (
        <>
            <PageHeader activeClass01='active' activeClass02='d-none' routeName01='FAQ' heading='Frequently Asked Question' />

            <div className="main_wrapper">
                <div className="faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-12">
                                <div className="images-faq">
                                    <img src="images/man.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="section_header">
                                    <h6 className="section_sub_title">Frequently Asked Question</h6>
                                    <h1 className="section_title">Do You Want to Know Anything From Us?</h1>
                                </div>
                                <div className="accordion">
                                    <div className="item active">
                                        <div className="accordion_tab">
                                            <h2 className="accordion_title">Floor Plan Descriptions</h2>
                                            <span className="accordion_tab_icon">
                                                <i className="open_icon ion-ios-arrow-down"></i>
                                                <i className="close_icon ion-ios-arrow-up"></i>
                                            </span>
                                        </div>
                                        <div className="accordion_info">
                                            Discover the perfect layout for your needs with our diverse range of floor plans. Each plan is meticulously designed for optimal space utilization, aesthetic appeal, and structural integrity. Whether you’re looking for residential comfort or commercial functionality, our plans are tailored to meet your specific requirements.
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="accordion_tab">
                                            <h2 className="accordion_title">Construction Technology Used</h2>
                                            <span className="accordion_tab_icon">
                                                <i className="open_icon ion-ios-arrow-down"></i>
                                                <i className="close_icon ion-ios-arrow-up"></i>
                                            </span>
                                        </div>
                                        <div className="accordion_info">
                                            At SkyLineCityNY, we leverage cutting-edge technology to ensure precision, efficiency, and safety in every project. Our arsenal includes advanced BIM software for detailed modeling, eco-friendly materials for sustainable construction, and innovative structural solutions that stand up to New York's unique architectural demands.
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="accordion_tab">
                                            <h2 className="accordion_title">Thoughts Behind the projects</h2>
                                            <span className="accordion_tab_icon">
                                                <i className="open_icon ion-ios-arrow-down"></i>
                                                <i className="close_icon ion-ios-arrow-up"></i>
                                            </span>
                                        </div>
                                        <div className="accordion_info">
                                            Each SkyLineCityNY project is more than a structure; it’s a vision brought to life. Our designs reflect the harmony between urban landscapes and functional art. We believe in creating buildings that enhance their environment, serve their purpose magnificently, and inspire all who engage with them.
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="accordion_tab">
                                            <h2 className="accordion_title">Sustainability and Eco-Friendly Practices</h2>
                                            <span className="accordion_tab_icon">
                                                <i className="open_icon ion-ios-arrow-down"></i>
                                                <i className="close_icon ion-ios-arrow-up"></i>
                                            </span>
                                        </div>
                                        <div className="accordion_info">
                                            Embracing the future of construction means committing to sustainability. At SkyLineCityNY, environmental responsibility is integral to our operations. We incorporate green building practices, from using renewable materials and energy-efficient designs to minimizing waste on-site. Our goal is to create structures that not only serve our clients but also contribute positively to our planet's well-being.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Banner
                    title='Expert construction at your service! SkyLineCityNy for robust and reliable building solutions.'
                    heading='Call Us Anytime'
                    phone='1-844-444-4097'
                    bannerType='banner banner_bg_color'
                />

            </div>
        </>
    );
};

export default FAQ;
