import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS7 = () => {
    const nombre = './assets/Bedford_Stuyvesant2.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Bedford Stuyvesant Brooklyn</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Nestled in the historic tapestry of Brooklyn's vibrant streets lies Vernon Avenue, a character-filled three-story, two-family brick townhouse that beckons with the charm of yesteryears. Currently presented as a single-family home, this townhouse offers an abundance of space with approximately 2,235 square feet of living area, complemented by a generous 3,062 gross square feet when including the cellar. Its prime location ensures a swift commute to Manhattan and Williamsburg, with the J, M, Z, and G subway lines just a stone's throw away.</p>

                                        <p>
                                            Vernon Avenue is more than a home; it's a canvas awaiting the brushstrokes of restoration to bring out its inherent grandeur. Original architectural elements like marble mantels, pocket doors, an intact wood staircase, and classic molding are a testament to its enduring beauty. The substantial lot of 18 x 100 feet sits under the R6B zoning with an FAR of 2.0, presenting an invaluable opportunity to further develop an additional 1,406 square feet of buildable space.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>The marble mantels stand as an emblem of the property's opulent past, offering a focal point for each room's decor and a promise of elegant evenings by the hearth.</li>
                                            <li>Pocket doors reveal the craftsmanship of a bygone era, their smooth operation and woodwork detail contributing to the home’s historical charm and functional design.</li>
                                            <li>The original wood staircase and banister serve not only as a means of passage but as a heritage feature, winding upwards through the home with a warm patina that whispers stories from years past.</li>
                                            <li>The home's molding and woodwork throughout the residence offer a sophisticated frame to each room, speaking to the artisanal skill and attention to detail of its original craftsmen.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS5_Vernon-ave.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS5_vernonvenue.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            To ensure this property reaches its full potential, our dedicated team has devised a systematic approach. Initially, we will conduct a thorough assessment of the home's current condition, highlighting areas that necessitate immediate attention and those that can be enhanced to elevate its historic value. In parallel, our design team will begin crafting a bespoke renovation plan that respects the home's original features while infusing modern comforts and efficiencies.
                                        </p>
                                        <p>Our next phase will focus on the delicate execution of these renovations, ensuring that each step is undertaken with precision and reverence for the home's architectural integrity. Our network of skilled artisans and modern technicians will collaborate to seamlessly blend the old with the new, delivering a finished product that is both functional and aesthetically pleasing.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Delve into the architectural blueprint of Vernon Avenue, where the spacious rooms are laid out in a harmonious flow that invites both lively gatherings and tranquil moments.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Modern restoration techniques meet traditional craftsmanship, employing innovative methods to preserve and enhance the home's historical essence.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Our vision for Vernon Avenue is one of respect and renewal, aiming to breathe new life into its spaces while honoring the narrative embedded in its walls.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    With its additional buildable space and prime location, Vernon Avenue stands as a sound investment with significant potential for appreciation in the burgeoning Brooklyn market.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            Vernon Avenue presents a rare opportunity to own a piece of Brooklyn's heritage. With its generous dimensions, preserved historical details, and additional development potential, it is poised to become a treasured home for those with a vision to curate its next chapter.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>I was immediately drawn to the timeless elegance of Vernon Avenue. Seeing the original features preserved and imagining the possibilities was a dream come true. Throughout the process, the team's expertise and sensitivity to the home's history made all the difference. Now, with the renovations complete, every corner of this home tells a story, and it's a joy to be a part of its continuing legacy.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS7;