import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS6 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS6';

const ProjectDetailsRS6 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Bedford Stuyvesant' />

            <ProjectDetailsContainerRS6 />

        </>
    );
};

export default ProjectDetailsRS6;