import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainerConsulting from '../../Containers/ServiceContainers/ServiceContainerConsulting';

const ServiceDetailsConsulting = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Consulting' heading='Consulting' />
            <ServiceContainerConsulting />
        </>
    );
};

export default ServiceDetailsConsulting;