import React from 'react';
import Video from '../../Components/BannerElements/Video';
import Testimonial01 from '../Testimonials/Testimonial01';

const ServiceContainer03 = () => {
    return (
        <div className="main_wrapper">
            <div className="container">
                <div className="project_details section">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="project_details_inner">
                                <div className="post_img">
                                    <img src="images/services/details.png" alt="blog" />
                                </div>
                                <div className="post_content">
                                    <div className="post_header">
                                        <h3 className="post_title">Commercial Design & Construction</h3>
                                    </div>
                                    <div className="fulltext">
                                        <p>
                                            Skyline City Team is at the forefront of transforming urban landscapes through our specialized Commercial Design & Construction services. We pride ourselves on creating commercial structures that are not only landmarks of design innovation but also epitomes of functional excellence.
                                        </p>
                                        <p>
                                            Our approach to commercial construction is holistic, encompassing meticulous planning, cutting-edge architectural design, and precise civil engineering to construct facilities that meet the commercial demands of our clients. Whether it's a state-of-the-art office complex, a retail hub, or an industrial facility, Skyline City Team ensures that every project is synonymous with quality and sustainability.
                                        </p>
                                        <p>
                                            With a deep understanding of the commercial construction landscape, Skyline City Team's expertise extends to tailored design solutions that maximize space utilization, enhance operational efficiency, and integrate smart building technologies. Our commitment to pushing the boundaries of design and engineering ensures that your commercial space stands out in the skyline and serves as a beacon of modern construction.
                                        </p>
                                        <p>
                                            Trust Skyline City Team to bring your commercial construction vision to life with unparalleled professionalism and technical excellence.
                                        </p>

                                        <h4 className="widget_title">
                                            Service Steps
                                            <span className="title_line"></span>
                                        </h4>
                                        <p className="margin_o_para">Skyline City Team's Commercial Design & Construction process is designed to ensure a seamless, efficient, and high-quality build from concept to completion.</p>
                                        <ul>
                                            <li>
                                                <strong>Conceptualization</strong>
                                                <ul className="point_order">
                                                    <li>Engage with stakeholders to crystallize the project vision.</li>
                                                    <li>Conduct feasibility studies and preliminary design sketches.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Design Development</strong>
                                                <ul className="point_order">
                                                    <li>Translate concepts into detailed architectural plans.</li>
                                                    <li>Utilize 3D modeling for a tangible preview of the proposed structure.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Regulatory Compliance</strong>
                                                <ul className="point_order">
                                                    <li>Ensure designs meet all local building codes and regulations.</li>
                                                    <li>Obtain necessary permits to pave the way for construction.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Project Execution</strong>
                                                <ul className="point_order">
                                                    <li>Mobilize construction teams and manage the build process.</li>
                                                    <li>Apply rigorous quality control for every phase of construction.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Final Delivery</strong>
                                                <ul className="point_order">
                                                    <li>Conduct thorough inspections and final walkthroughs.</li>
                                                    <li>Hand over the completed project, ensuring it surpasses client expectations.</li>
                                                </ul>
                                            </li>
                                        </ul>

                                        <div className="post_gallery">
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="anim_box">
                                                        <img src="images/services/Serv_Detail_Comm1.jpg" alt="img" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="anim_box">
                                                        <img className="sm-margin-bottom" src="images/services/Serv_Detail_Comm3.jpg" alt="img2" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            Skyline City Team stands as a beacon of innovation in the commercial design and construction landscape. We bring to the forefront a blend of aesthetic appeal and practical functionality, ensuring that each project is not just a structure but a landmark of efficiency and design. Our approach to lighting transcends mere illumination; it's about crafting spaces that are safe, inviting, and conducive to the commercial activities within.
                                        </p>
                                        <p>Our commitment to seamless integration of technology is evident in our adoption of sophisticated access systems, ensuring that the flow of people through our spaces is both secure and unobtrusive. The automation of such systems doesn't just add to the convenience; it enhances the architectural integrity and operational fluidity of the commercial spaces we construct.</p>

                                        <Video bg='images/services/video_bg.png' />

                                        <p>
                                            And finally, our intelligent use of space speaks of a deep understanding of modern commercial needs. Every design decision we make at Skyline City Team is underpinned by the drive to maximize the utility and value of the property. From the grandiose facade to the intricate workings of building services, our projects are a testament to what modern commercial construction can achieve when it's helmed by vision and executed with precision.
                                        </p>

                                        <p>
                                            Skyline City Team’s commercial design and construction services are not just about building structures; they're about erecting pillars of progress that will stand the test of time and serve the community for generations to come.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="sidebar">
                                <div id="widgetnav" className="widget widget_menu">
                                    <div className="sidenav">
                                        <ul className="side_menu">
                                            <li className="menu-item active">
                                                <a href="/service-details-Commercial">
                                                    <img src="images/services/cone_1.png" alt="" />
                                                    <img src="images/services/2-white.png" alt="" />
                                                    Commercial Design
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/service-details-RealEstate">
                                                    <img src="images/services/9-blue.png" alt="" />
                                                    <img src="images/services/cone_2.png" alt="" />
                                                    Real Estate & Housing
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/service-details-Demolition">
                                                    <img src="images/services/cone_3.png" alt="" />
                                                    <img src="images/services/5-blue.png" alt="" />
                                                    Demolition
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/service-details-Electrical">
                                                    <img src="images/services/cone_4.png" alt="" />
                                                    <img src="images/services/1-white.png" alt="" />
                                                    Electrical
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/service-details-Plumbing">
                                                    <img src="images/services/cone_5.png" alt="" />
                                                    <img src="images/services/11-white.png" alt="" />
                                                    Plumbing
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/service-details-ExteriorWork">
                                                    <img src="images/services/cone_6.png" alt="" />
                                                    <img src="images/services/10-white.png" alt="" />
                                                    Exterior Work
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/service-details-Consulting">
                                                    <img src="images/services/cone_7.png" alt="" />
                                                    <img src="images/services/7-white.png" alt="" />
                                                    Consulting
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/service-details-3DRendering">
                                                    <img src="images/services/cone_5.png" alt="" />
                                                    <img src="images/services/11-white.png" alt="" />
                                                    3D Rendering
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/service-details-HVAC">
                                                    <img src="images/services/cone_7.png" alt="" />
                                                    <img src="images/services/7-white.png" alt="" />
                                                    HVAC
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div id="custom_2" className="widget widget_side_contact">
                                    <div className="side_contact">
                                        <div className="side_contact_top">
                                            <div className="side_contact_inner">
                                                <p>Need Construction Service?</p>
                                                <h4>Make An Appointment</h4>
                                            </div>
                                        </div>
                                        <div className="side_phone_inner">
                                            <div>1-844-444-4097</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonial01 pb='pd_btom_80 pd_top_80' />
        </div>
    );
};

export default ServiceContainer03;