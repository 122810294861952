import $ from 'jquery';
import React, { useEffect } from 'react';
import Sliders from './ImageGallery';

const ProjectDetailsContainerConstRegopark = () => {
    const nombre = './assets/RegoPark.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Rego Park Modern Renewal: Redefining Comfort with a Contemporary Touch</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>This Rego Park residence has undergone a thoughtful and modern renovation by Skyline City. The project was designed to enhance both functionality and style while staying true to the classic character of the home. With updated finishes, new flooring, and a complete kitchen and bathroom remodel, this renovation provides the perfect balance of sleek, contemporary living with the charm of a Queens neighborhood home.</p>
                                        <p>In the kitchen, our team installed custom cabinetry in a clean, white palette paired with stainless steel appliances, ensuring both form and function for the homeowner. The tile floors and backsplash create a cohesive and modern aesthetic that complements the natural light flooding the space. From the kitchen, the open floor plan flows effortlessly into the living and dining areas, providing a seamless transition ideal for both everyday living and entertaining.</p>
                                        <p>Upstairs, we revitalized the attic space, transforming it into a cozy and functional room perfect for a home office or a creative studio. Throughout the home, we updated the flooring with sleek, durable tiles and refinished hardwood floors, elevating the overall look while ensuring long-term durability. The outdoor patio area was also refreshed, making it a perfect retreat for relaxation or social gatherings.</p>
                                        <h4 class="widget_title">
                                            Sleek and Functional Design
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Modern Living in the Heart of Queens</p>
                                        <ul class="point_order">
                                            <li>Contemporary Kitchen Remodel: Bright, custom cabinetry and new stainless-steel appliances offer a polished, modern feel while maximizing kitchen space for convenience and style.</li>
                                            <li>Open Concept Living Spaces: The renovation opens up the main floor, creating a flowing and cohesive layout that allows natural light to brighten the entire home, from living room to kitchen.</li>
                                            <li>Versatile Attic Space: The attic was converted into a multi-functional area, ideal for a home office, additional bedroom, or personal retreat.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/regopark_ori_26.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/regopark_ori_6.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Embarking on this house renovation, we were inspired to create a living space that resonates with both the charm of yesteryears and the convenience of modern-day amenities. Our commitment was to ensure that each space within the home was both functional and aesthetically pleasing.
                                        </p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The newly reimagined floor plan optimizes open-concept living, offering an expansive layout that combines the living room, dining area, and kitchen. The space is bathed in natural light from the large windows, making it ideal for everyday life or entertaining guests.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Skyline City used state-of-the-art construction techniques in this project, incorporating high-efficiency insulation, energy-saving windows, and premium materials to ensure the home is as functional as it is beautiful.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Design Philosophy</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Our design goal was to bring modern functionality to a classic Queens home without sacrificing its original charm. The choice of materials and finishes was curated to reflect a contemporary yet warm atmosphere throughout the house.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Modern Amenities for Today's Lifestyle</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The home features upgraded appliances, enhanced lighting systems, and a fresh, modern aesthetic that caters to the needs of today’s fast-paced lifestyle while maintaining comfort and style.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                        This renovation in Rego Park is a testament to Skyline City’s commitment to creating modern, comfortable living spaces while respecting the character of the neighborhood. With its modern upgrades and thoughtful design, this home now offers the best of both worlds—classic Queens charm paired with sleek, contemporary amenities. We take pride in having delivered a home that exceeds our client’s expectations for beauty, functionality, and comfort.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>Skyline City completely transformed our Rego Park home into a bright, modern space that we now love living in. The attention to detail, from the sleek kitchen cabinets to the refinished floors, has truly elevated the overall feel of the home. We especially love how they opened up the layout, making the home feel much larger and more inviting. The attic space they converted is a fantastic addition, providing us with the extra room we needed. We're thrilled with the results and would highly recommend Skyline City for any renovation project!</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>House</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Two Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerConstRegopark;