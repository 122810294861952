import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerConstHerkimer from '../../Containers/ProjectContainers/ProjectDetailsContainerConstHerkimer';

const ProjectDetailsConstHerkimer = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='House Renovation' />

            <ProjectDetailsContainerConstHerkimer/>

        </>
    );
};



export default ProjectDetailsConstHerkimer;





/*
ProjectDetailsReno2 ->ProjectDetailsConstHerkimer
ProjectDetailsContainerConstGreatNeck -> 

*/
