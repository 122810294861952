import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainerRealEstate from '../../Containers/ServiceContainers/ServiceContainerRealEstate';

const ServiceDetailsRealEstate = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Real Estate & Housing' heading='Real Estate' />
            <ServiceContainerRealEstate />
        </>
    );
};

export default ServiceDetailsRealEstate;
