import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS2 = () => {
    const nombre = './assets/EastFlatbush_Bk.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">East Flatbush Solution </h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Embrace the seamless blend of classic architecture and modern refinement with this Craftsman style gem in East Flatbush. This home greets you with a warm pre-war feel, elevated by recent renovations that speak to both its historical integrity and its contemporary appeal. From the sun-drenched formal living room to the communal vibrancy of the open dining room and kitchen, each space is crafted for connection and celebration. Beyond the interiors, step onto the deck and descend to a private patio oasis, ideal for relaxation and lively barbecues.</p>

                                        <p>
                                            The upper level's three spacious bedrooms offer solace and style, complemented by a renovated bathroom that exudes modern luxury. Above, an attic provides practical storage solutions, while the basement's transformation into a cool, separate living space exemplifies thoughtful utilization of every square foot. Kitchen updates showcase brand new stainless steel appliances, quartz countertops, and custom cabinetry, creating a culinary haven that is both beautiful and functional.
                                        </p>

                                        <Video videoLink="https://www.youtube.com/embed/BdGlnKo78E4" bg='images/services/RS2_Video_img.jpg' />

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>Restored original parquet flooring and a handcrafted staircase anchor the home's warmth and character, offering a nod to its storied past.</li>
                                            <li>The kitchen's new pendant light fixtures cast an inviting glow over the quartz-topped peninsula, perfect for casual meals or morning coffee.</li>
                                            <li>Bathrooms are revitalized with tasteful fixtures, white subway tiles, and sleek shelving, combining aesthetics with utility.</li>
                                            <li>The patterned concrete deck and expansive patio provide an urban retreat, enhancing the home's indoor-outdoor living experience.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS2_Livingroom.jpeg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS2_Kitchen.jpeg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            The transformation of this residence has been meticulously managed to preserve its historical essence while infusing it with the necessities and luxuries of modern living. Upgraded plumbing and electrical systems provide the backbone for a home that promises both reliability and comfort for years to come. With a focus on sustainability and efficiency, the house is not only a haven for today's lifestyle but also a forward-thinking investment.
                                        </p>
                                        <p>As stewards of architectural integrity, we've meticulously planned every stage of the renovation to preserve the building's historic essence while infusing it with contemporary comfort. Our approach is detailed, deliberate, and dedicated to excellence.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    This Craftsman house reveals a floor plan where each room flows naturally into the next, from a sunlit living area to an inviting open-concept kitchen. The second floor houses three bedrooms, each with thoughtful touches that cater to comfort and restfulness. The attic, a quaint nod to the home's history, offers ample storage or a creative space. In the basement, find a versatile area with a private entrance, ready for guests or a quiet retreat.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The home's renovation balances historic integrity with the latest advancements in construction, featuring updated plumbing, electrical systems, and energy-efficient appliances. New exterior finishes and siding ensure long-term durability and aesthetic appeal. Smart home features are subtly integrated, offering convenience without detracting from the home's traditional vibe.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The restoration aimed to preserve the original parquet flooring and staircase, maintaining the home's historic character while introducing modern comforts. The selection of timeless materials ensures that the pre-war charm is not just retained but highlighted, offering a warm welcome to all who enter.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    With thoughtful upgrades and careful preservation, this property stands as a testament to sustainable development in East Flatbush. The careful planning of indoor and outdoor spaces suggests a deep understanding of urban living, where each square foot is designed to enrich the homeowner's lifestyle.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            This East Flatbush residence stands as a beacon of craftsmanship, where every update and preserved detail contributes to a living space that is both timelessly elegant and comfortably modern. It's a place where history is treasured, enhancements are enjoyed, and the future is bright with potential.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>We were immediately captivated by the harmonious blend of classic and contemporary elements. Each room tells a story, and the thoughtful renovations have transformed this house into our cherished home. From the peaceful afternoons on the deck to the cozy gatherings in the living room, our experiences here have been nothing short of magical.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS2;