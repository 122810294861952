import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerConsWhitestone from '../../Containers/ProjectContainers/ProjectDetailsContainerConsWhitestone';

const ProjectDetailsConstWhitestone = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='House Renovation' />

            <ProjectDetailsContainerConsWhitestone />

        </>
    );
};



export default ProjectDetailsConstWhitestone;

