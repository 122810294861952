import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS17 = () => {
    const nombre = './assets/DitmasPark.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Ditmas Park</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Embarking on a journey back in time with modern comforts, this grand 3-story residence, perched on the edge of Victorian Ditmas Park and Midwood, stands as a testament to the craftsmanship of 1910. Wrapped in the warm embrace of its pre-war details, the home's fully detached stature boasts a 2-car garage, private driveway, and an expansive lot. The welcoming enclosed front porch opens to an elegant foyer with a commanding staircase, leading to an array of formal and intimate spaces, including a sumptuous living room with a decorative fireplace, an opulent dining room, and cozy bedrooms bathed in natural light from stained glass windows.

                                        </p>

                                        <p>
                                            The property's interior narrative is one of timeless elegance and layered history. The original pocket doors slide open to reveal a formal dining room, where countless stories have unfolded, while the wood-paneled walls and parquet floors whisper the secrets of bygone eras. The top floor apartment, with its separate kitchen, offers a quaint retreat or an opportunity for rental income, and the large windowed basement hints at untapped potential. This house, a stone's throw from the B/Q express trains, promises a canvas for creating new memories while cherishing the old.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>The living room's decorative fireplace serves as a focal point, surrounded by plaster moldings and a mantle, evoking the elegance of past eras, while stained glass windows scatter colorful light across the polished parquet floors.</li>
                                            <li>In the dining room, the original pocket doors and bay windows frame the space with a sense of historical grandeur, complemented by the gleaming parquet floors that feature intricate inlays, demonstrating the meticulous attention to detail.</li>
                                            <li>Each bedroom is a sanctuary of comfort, with large windows inviting sunlight to dance on the hardwood floors, and the preserved woodwork adding a touch of sophistication and continuity with the home's historic charm.</li>
                                            <li>The enclosed front porch, with its paneled windows, offers a serene spot to enjoy the neighborhood's verdant tranquility, while the centered staircase in the entry foyer exudes an inviting sense of arrival with its detailed woodwork and stately presence.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS22_center-hall.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS22_dinning-room-2.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Upon entering this storied abode, one is immediately struck by the union of grandeur and homeliness. The service steps begin with a thorough historical consultation, ensuring the maintenance and enhancement of the property's unique character. Specialists in pre-war architecture will inspect every cornice and molding, suggesting preservation strategies that respect the home's heritage while optimizing for modern living.
                                        </p>
                                        <p>Concurrently, an assessment of the spacious basement's potential will be conducted. Considering the separate entrance, this area presents a wealth of possibilities, from a creative studio to an additional living quarter. Discussions will be held to determine the best use of this space, aligning with the homeowner's vision and the property's structural capabilities.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The floor plan of this brownstone unfolds over three stories, presenting a layout that is as functional as it is beautiful. The parlor level exudes traditional elegance, ideal for formal gatherings, while the garden level's open concept supports a more relaxed, modern lifestyle. The top-floor apartment, with its own unique charm, offers flexible opportunities for guest accommodation or rental income.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Blending the old with the new, the brownstone's renovations were completed using advanced construction technologies. Precision tools and modern materials were employed to reinforce the building's historical integrity while introducing updated plumbing, electrical, and HVAC systems that meet contemporary standards for efficiency and comfort.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The restoration of this brownstone was guided by a deep respect for its architectural heritage, matched by a commitment to modern functionality. The vision was to create a living space that honors the building's storied past without compromising on the conveniences demanded by today's homeowners.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    This brownstone's development was approached with a keen eye on the balance between preservation and market trends. The enhancements were strategically planned to augment the property's value and appeal, ensuring that it stands out in Brooklyn's competitive real estate market, while preserving the neighborhood's historic fabric.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            This brownstone is more than just a dwelling; it's a piece of Brooklyn's heartbeat, a testament to the borough's enduring spirit. The careful curation of its original features with thoughtful updates ensures that it offers not just a residence, but a legacy. For the discerning buyer, it represents a rare opportunity to partake in the storied tapestry of one of New York's most celebrated communities.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>We were instantly captivated by the elegance of this Crown Heights limestone, a true gem that combines the grandeur of its historic roots with the comfort of modern updates. Each original detail—from the intricate wainscoting to the stately wood details—tells a story of craftsmanship and heritage. Our family gatherings have been elevated by the property's generous backyard, an oasis of tranquility for entertaining and relaxation. The team that guided us through the acquisition process demonstrated not only a deep understanding of the property's value but also a remarkable dedication to aligning it with our aspirations. This isn't just our house; it's our forever home, where the legacy of the past and the promise of the future blend seamlessly.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS17;