import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS4 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS4';

const ProjectDetailsRS4 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='CROWN HEIGHTS' />

            <ProjectDetailsContainerRS4 />

        </>
    );
};

export default ProjectDetailsRS4;