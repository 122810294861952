import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS5 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS5';

const ProjectDetailsRS5 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Lincoln Square' />

            <ProjectDetailsContainerRS5 />

        </>
    );
};

export default ProjectDetailsRS5;