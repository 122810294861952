import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainer3DRendering from '../../Containers/ServiceContainers/ServiceContainer3DRendering';

const ServiceDetails3DRendering = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='3D Rendering' heading='3D Rendering' />
            <ServiceContainer3DRendering />
        </>
    );
};

export default ServiceDetails3DRendering;