import $ from 'jquery';
import React, { useEffect } from 'react';
import Sliders from './ImageGallery';

const ProjectDetailsContainerConstCrownH = () => {
    const nombre = './assets/Const_CrownHeights.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Building Renovation</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Discover the perfect blend of historical charm and contemporary design in our latest building renovation. This property, nestled in the heart of the city, stands as a testament to the beauty of blending the old with the new.</p>

                                        <p>
                                            Step inside to find a space where every corner has been thoughtfully designed to maximize comfort and style. The original hardwood floors have been meticulously restored to their former glory, offering a warm welcome that pairs beautifully with the modern and sleek kitchen, where stainless steel appliances and granite countertops offer the height of urban living.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>The bathrooms exhibit a sense of luxury with their artistic tile work and state-of-the-art fixtures, creating a serene space that feels both timeless and modern. Gold accents bring a touch of opulence, while the sleek design keeps the space feeling fresh and clean.</li>
                                            <li>Experience breathtaking views of the city skyline from the comfort of your own home. The windows frame the bustling life outside, connecting you to the urban pulse while you enjoy the peaceful sanctuary of your living space.</li>
                                            <li>With convenience at the forefront, the property features energy-efficient lighting and appliances, in-unit laundry facilities, and smart home technology. These modern amenities ensure a comfortable and sustainable lifestyle that meets the demands of contemporary city living.</li>
                                            <li>From the classic staircase that tells stories of the past to the modern lighting that illuminates the way forward, this renovation honors the building's history while embracing the future. The result is a residence that offers an unmatched living experience in the city.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Renovation_Buildp1.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Renovation_Buildp3.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Embarking on a journey to revitalize history, our building renovation project is a blend of meticulous craftsmanship and modern functionality. From the polished hardwood floors to the integration of smart home technology, every aspect of the renovation process has been carefully orchestrated to ensure a seamless transition from the past to the present.
                                        </p>
                                        <p>As stewards of architectural integrity, we've meticulously planned every stage of the renovation to preserve the building's historic essence while infusing it with contemporary comfort. Our approach is detailed, deliberate, and dedicated to excellence.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The renovated building's floor plan is a celebration of open spaces and natural flow. The architectural design enhances the original structure's layout, offering expansive living areas, bright and airy rooms, and a harmonious balance between shared and private spaces.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Leveraging state-of-the-art construction technology, we've reinforced the building's timeless framework with modern materials that meet today's standards for safety and sustainability. From energy-efficient systems to advanced insulation techniques, we've built for the future while honoring the past.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Our vision was to create a living space that resonates with the vibrancy of the city while providing a tranquil retreat from it. Each design decision was made with the dual intent of preserving the historical character of the building and delivering a modern urban lifestyle to its residents.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Innovative Solutions for Enhanced Pharmacy Operations</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Innovation underpins our renovation efforts. Smart home features, eco-friendly appliances, and automated climate control systems are just a few of the contemporary enhancements that define our approach to modern living. These technologies not only contribute to comfort but also advocate for environmental responsibility.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            After the completion of our renovation, the building stands as a beacon of historical preservation and modern sophistication. It's not merely a place to reside; it's a space that tells a story, a home that offers a unique narrative woven through the very fabric of its walls.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>Stepping into the renovated building was like walking into a perfect marriage of history and modernity. The designers have done a magnificent job preserving the soul of the original structure while providing all the modern comforts we've come to expect. Every morning, I'm greeted by the gleaming original hardwood floors and the sunlight pouring through the large windows, offering a stunning view of the cityscape. The state-of-the-art kitchen and smart home features have made living here not just luxurious but incredibly convenient. It's clear that every detail was thoughtfully considered to create a living experience that's truly second to none. I'm proud to call this my home.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerConstCrownH;

