import $ from 'jquery';
import React, { useEffect } from 'react';
import Sliders from './ImageGallery';

const ProjectDetailsContainerConstHarrington = () => {
    const nombre = './assets/Harrington.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Harrington Avenue Redefined: Where Modern Comfort Meets Timeless Style</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Discover the transformation of this Bronx residence into a harmonious blend of modern design and timeless charm. Harrington Avenue now boasts a fully upgraded interior with high-quality finishes that elevate both comfort and functionality. From the luxurious hardwood floors to the meticulously designed kitchen and bathroom spaces, this home is ready to cater to contemporary lifestyles while maintaining a warm, inviting ambiance.</p>
                                        <p>The kitchen, a focal point of this renovation, has been fitted with sleek grey shaker cabinets, polished quartz countertops, and a stunning marble-style backsplash. Stainless steel appliances seamlessly integrate into the design, making this a culinary space that not only looks great but also works flawlessly. The open-concept layout invites natural light to fill the rooms, creating a bright and airy living environment.</p>
                                        <p>Throughout the home, every detail has been carefully selected to enhance both form and function. The new bathroom features elegant tile work and a modern rainfall shower, while the exterior renovations, including a revamped façade and private backyard, ensure this property is as striking from the outside as it is from within. Skyline City Team has skillfully blended classic architectural elements with contemporary upgrades to create a home that feels as good as it looks.</p>
                                        <h4 class="widget_title">
                                            Sleek and Functional Design
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Innovative Design for Contemporary Living</p>
                                        <ul class="point_order">
                                            <li>High-End Finishes: Newly installed hardwood floors, quartz countertops, and stainless steel appliances bring modern sophistication to every room.</li>
                                            <li>Bright and Open Spaces: Large windows and a neutral color palette create a spacious and airy environment, allowing natural light to flood the living areas.</li>
                                            <li>Energy-Efficient Upgrades: All lighting has been upgraded to LED fixtures, ensuring eco-friendly functionality throughout the home.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/HarringtonAve_Bronx_15.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/HarringtonAve_Bronx_7.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Embarking on this house renovation, we were inspired to create a living space that resonates with both the charm of yesteryears and the convenience of modern-day amenities. Our commitment was to ensure that each space within the home was both functional and aesthetically pleasing.
                                        </p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The carefully considered floor plan maximizes space and flow throughout the home, from the open-concept living areas to the private and serene bedrooms. Each room has been designed with both function and aesthetics in mind to create a living environment that is as practical as it is beautiful.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Our team employed cutting-edge construction technologies, including energy-efficient materials, high-quality insulation, and state-of-the-art lighting systems, ensuring the home is environmentally friendly and built to last.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Design Philosophy</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The design approach focused on blending modern aesthetics with functionality. By maintaining clean lines, a neutral color palette, and natural materials like hardwood, this home exudes timeless elegance while meeting the demands of contemporary living.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Modern Amenities for Today's Lifestyle</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                This home features smart, energy-efficient lighting and heating solutions, a modern kitchen equipped with high-end appliances, and bathrooms designed for both luxury and practicality, ensuring it accommodates the needs of today’s homeowners.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                        Skyline City Team is proud to have brought new life to this Bronx residence, transforming it into a space where modern amenities and timeless design converge. Every element, from the architectural enhancements to the sophisticated finishes, reflects our commitment to quality and attention to detail. Harrington Avenue is now a place where families can create lasting memories, all while enjoying the comfort and style of a thoughtfully designed home.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>Skyline City Team has exceeded our expectations with the Harrington Avenue renovation. From the stunning kitchen design to the spacious and bright living areas, every detail was considered and expertly executed. The team’s professionalism and eye for detail were unmatched, and we couldn’t be happier with our new home. This is the perfect blend of elegance and practicality.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>House</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Two Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerConstHarrington;