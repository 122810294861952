import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS10 = () => {
    const nombre = './assets/MidtownEast.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Midtown East NY</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Situated in the bustling heart of Midtown East, this generously sized studio condominium at 135 East 54th Street is a blank canvas awaiting your unique touch. The living space, a sweeping 450 square feet, bathes in the glow of New York's dynamic spirit, offering a flexible floor plan ripe for transformation into a chic one-bedroom retreat. With a retro-style kitchen as your culinary playground, enjoy high ceilings that stretch skyward, echoing the city's towering aspirations, while the rich texture of hardwood floors adds warmth to the modern aesthetic. Framed by the iconic golden domes of neighboring edifices, this abode is not just an address; it's a statement of urban sophistication.</p>

                                        <p>
                                            Residing in the Lex 54 Condominium, a testament to Rosario Candela's timeless architectural prowess, you are cradled in luxury and convenience. The hustle of the city melts away as you ascend in the attentive embrace of a full-service, doorman-attended elevator, ascending to a private sanctuary that promises serenity amid the city's symphony. Here, high ceilings impart a sense of boundless possibility, and the expansive windows serve as your personal viewport to the Midtown East's grandeur, an area peppered with the most coveted residences in the city.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>The open kitchen is a nod to classic charm with a modern twist, featuring sleek countertops and ample cabinetry that beckon culinary exploration and sophisticated entertaining.</li>
                                            <li>Hardwood floors in a honeyed hue provide a timeless elegance underfoot, their natural warmth contrasting the clean lines and high ceilings that define this spacious studio.</li>
                                            <li>Each glance out the window frames a living portrait of New York's famed architecture, the golden domes nearby gilding your view with an almost magical quality as day turns to night.</li>
                                            <li>The promise of a convertible layout allows for creative expression; envision a partition that adds an intimate bedroom sanctuary or a flowing, open-plan design that captures the essence of city living.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS11_kitchen.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS11_lvnrm.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Embarking on the journey of transforming this versatile space into your dream city dwelling involves a few key steps, each crafted to ensure a seamless transition from vision to reality. Our first step is a personalized consultation to understand your unique style and needs. Following this, a meticulous planning phase will outline the conversion of the studio layout to a one-bedroom configuration, aligning every detail with your aspirations.
                                        </p>
                                        <p>To bring this vision to life, we collaborate with skilled craftsmen and designers who respect the building's heritage while infusing contemporary flair. Each material and finish is carefully selected to resonate with the property's character, from the lustrous hardwood floors to the bespoke kitchen fittings. We offer transparent, regular communication throughout the process, guaranteeing that your input is the cornerstone of every decision.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Unfolding the blueprints of 135 East 54th Street reveals a layout that marries the charm of Midtown elegance with the pragmatism of modern design. The proposed floor plan transforms the open studio space into a sophisticated one-bedroom sanctuary, with intelligent storage solutions and multifunctional areas that fluidly transition from the hustle of city life to the serenity of home.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Delving into the craftsmanship behind the walls, we find a commitment to superior construction methods. With acoustic insulation for peace among the city's symphony, high-grade materials assure longevity and bespoke finishes echo the architectural heritage. Advanced climate control systems are seamlessly integrated, ensuring comfort is a quiet constant in this urban retreat.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Every stroke of the designer's pen has been informed by a vision to create not just a living space, but a canvas for life's moments. The preservation of Rosario Candela's architectural ethos, paired with modern amenities, ensures the residence at Apartment 10F is a testament to timeless design and contemporary luxury.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The transformation of this condominium is a strategic endeavor set against the backdrop of Midtown East's illustrious skyline. By enhancing the property with a sensitive upgrade that heightens its historical value while incorporating modern luxuries, the development is poised to become a coveted address, marrying investment wisdom with residential bliss.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            In the heart of Midtown East, Apartment 10F at 135 East 54th Street stands as a beacon of urban renewal, exemplifying the harmony of past and present. This residence is a curated masterpiece, promising its dwellers not just a home, but a slice of New York legacy, polished with today's luxuries.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>From the moment we walked into Apartment 10F, we knew we had found not just our New York nest, but a piece of history crafted for the future. The seamless blend of Rosario Candela's timeless design with the sophisticated upgrades makes every corner a conversation with the city's past and a whisper of the future. Living here is not just an experience; it's a privilege.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS10;