import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainerElectrical from '../../Containers/ServiceContainers/ServiceContainerElectrical';

const ServiceDetailsElectrical = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Electrical' heading='Electrical' />
            <ServiceContainerElectrical />
        </>
    );
};

export default ServiceDetailsElectrical;
