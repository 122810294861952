import $ from 'jquery';
import React, { useEffect } from 'react';
import Sliders from './ImageGallery';

const ProjectDetailsContainerConsWhitestone = () => {
    const nombre = './assets/Whitestone3rdave.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Whitestone Luxe Renovation: Harmonizing Modern Comfort with Timeless Design</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Welcome to this meticulously transformed Whitestone residence, where every detail has been carefully curated to merge modern functionality with timeless elegance. Our team worked closely with the client to create spaces that reflect both contemporary design sensibilities and the home's classic charm. The renovation encompassed the entire house, with special attention given to the kitchen and bathrooms, transforming them into luxurious, yet practical spaces.</p>
                                        <p>In the kitchen, dark wooden cabinetry contrasts beautifully with a sleek backsplash and high-end stainless steel appliances, making this room not only the heart of the home but a statement of modern elegance. We also focused on functionality by incorporating an open-plan layout that seamlessly flows into the living and dining areas, enhancing the sense of space and making it perfect for family gatherings or entertaining guests.</p>
                                        <p>The bathrooms were upgraded to evoke a spa-like experience, featuring sophisticated vanities, frameless glass showers, and premium fixtures that combine style and ease of use. The attention to detail is seen throughout the home, from the carefully selected light fixtures in the living room to the intricate tile work and luxurious fittings in every corner. This project has not only refreshed the home's aesthetic but has also maximized comfort and utility for the homeowner.</p>
                                        <h4 class="widget_title">
                                            Sleek and Functional Design
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Luxury Meets Practicality in Every Detail</p>
                                        <ul class="point_order">
                                            <li>Elegant Kitchen Upgrade: A complete kitchen overhaul with custom cabinetry, premium stainless-steel appliances, and a modern tile backsplash designed for both function and style.</li>
                                            <li>Spa-Like Bathrooms: Luxurious, contemporary bathrooms featuring sleek vanities, frameless showers, and high-end fixtures that offer a serene and sophisticated environment.</li>
                                            <li>Open Living Spaces: Thoughtful renovation of living and dining areas to enhance flow, maximize natural light, and offer the perfect setting for relaxation and socializing.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Whitestone_Original_8.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Whitestone_Original_10.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Embarking on this house renovation, we were inspired to create a living space that resonates with both the charm of yesteryears and the convenience of modern-day amenities. Our commitment was to ensure that each space within the home was both functional and aesthetically pleasing.
                                        </p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The reimagined floor plan enhances the open, flowing layout, which allows natural light to permeate throughout the home. The kitchen, living, and dining areas blend seamlessly, creating a cohesive environment perfect for both everyday life and entertaining.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Our renovation incorporated the latest advancements in home construction, including energy-efficient windows, high-quality insulation, and state-of-the-art plumbing fixtures to ensure long-lasting quality and reduced environmental impact.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Design Philosophy</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Our goal was to marry contemporary functionality with the home’s existing character. We prioritized creating inviting spaces that reflect both luxury and practicality while preserving the home’s architectural integrity.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Modern Amenities for Today's Lifestyle</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The house is now outfitted with modern conveniences like touchless faucets, a high-efficiency heating and cooling system, and integrated smart home technology to suit today’s fast-paced lifestyle while maintaining a cozy, homely ambiance.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                        This Whitestone renovation represents a harmonious blend of traditional design and modern upgrades. The transformation has revitalized the entire home, offering the homeowners a space that is both inviting and practical. Each room now serves as a reflection of the client’s personal style while being equipped with all the conveniences necessary for modern living. We are proud to have delivered a home that promises comfort, luxury, and timeless appeal.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>We couldn't be happier with the transformation of our home. From the initial consultation to the final walk-through, the entire process was seamless and stress-free. The design team’s vision brought our outdated kitchen and bathrooms to life in ways we couldn’t have imagined. The attention to detail, especially in the custom cabinetry and the elegant bathrooms, exceeded our expectations. The flow between the kitchen and living areas has completely changed the way we use our space, making it more open and functional for our family. We are thrilled to call this beautifully renovated house our home, and we highly recommend the team at Skyline City Team for anyone considering a home renovation.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>House</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Four Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerConsWhitestone;