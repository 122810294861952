import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';



//import Image1 from '../../Real_Estate/2_EastFlatbush_Brooklyn_1/one_house-1536x1024.jpg';
//import Thumb1 from '../../Real_Estate/2_EastFlatbush_Brooklyn_1/one_house-370x300.jpg';



/*
import Image1 from '../../Real_Estate/2_EastFlatbush_Brooklyn_1/one_house-1536x1024.jpg';
import Thumb1 from '../../Real_Estate/2_EastFlatbush_Brooklyn_1/one_house-370x300.jpg';
import Image2 from '../../Real_Estate/2_EastFlatbush_Brooklyn_1/two_659-living-room-1536x1024.jpg';
import Thumb2 from '../../Real_Estate/2_EastFlatbush_Brooklyn_1/two_659-living-room-370x300.jpg';
import Image3 from '../../Real_Estate/2_EastFlatbush_Brooklyn_1/three_659-kitchen-1536x1024.jpg';
import Thumb3 from '../../Real_Estate/2_EastFlatbush_Brooklyn_1/three_659-kitchen-370x300.jpg';
*/


//<img src="images/projects/RS1_property-view.webp" alt="blog" />


const ProjectDetailsContainerRS1 = () => {
    const nombre = './assets/CrownHeights_Bk.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
/*
    const images = [
        {
            original : Image1,
            thumbnail: Thumb1,
        },
    ];
*/
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre}/> 
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Real Estate Solutions</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Discover the unique blend of historical allure and modern comfort in this exquisite 3-family brick building, now available for the first time on the market. Nestled in the vibrant Crown Heights neighborhood, this property is a perfect symphony of old-world charm and contemporary living. Each unit is vacant and ready to become the canvas for your next chapter. The main duplex boasts two bedrooms and one bathroom, complemented by a basement featuring a gym and extra storage, offering both practicality and relaxation. The second unit is a cozy, self-contained studio, ideal for modern city living. The crown jewel, our third unit, features two spacious bedrooms, an open concept kitchen, and a living area that radiates warmth and style.</p>

                                        <p>
                                            Step into a living space where history is preserved and future possibilities await. With the potential to expand upwards, this property not only promises a comfortable dwelling but also a wise investment in a constantly evolving Brooklyn landscape. Imagine a home that adapts with you, where every detail from the polished hardwood floors to the thoughtfully designed kitchens creates a harmonious balance between functionality and aesthetic appeal.
                                        </p>

                                        <Video videoLink="https://www.youtube.com/embed/B4FIMLLYbZY" bg='images/services/RS1_video_img.jpg' />

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>Each residence within the building is accented with unique features, such as hardwood floors that reflect the sunlight, adding an airy brightness to each day.</li>
                                            <li>Kitchens in every unit are designed with functionality and style in mind, featuring modern appliances and ample counter space for culinary explorations.</li>
                                            <li>Bathrooms are a testament to classic elegance, offering residents a private retreat with tastefully appointed fixtures and serene color palettes.</li>
                                            <li>The expansive living areas in each unit provide a versatile space, perfect for entertaining guests or enjoying quiet moments of relaxation.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS1_lvg-ktchn.webp" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS1_bedroom-2.webp" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            From the initial viewing to the final handover, our commitment to excellence is evident in every step of the process. We understand the importance of a seamless transition into your new home or investment property, and our team ensures meticulous attention to detail at every turn. By incorporating cutting-edge smart home technology, we've elevated the living experience to new heights, marrying convenience with luxury.
                                        </p>
                                        <p>As guardians of architectural heritage, we have judiciously navigated the restoration process, preserving the essence of the building's historical significance while infusing it with all the conveniences of modern living. Our approach is methodical, thoughtful, and aimed at achieving unparalleled excellence.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Maximizing space and light, the duplex offers an inviting open-plan living area, while the studio is a model of modern efficiency. The top-floor apartment features an airy kitchen and living space, with bedrooms that afford serene city views.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Advanced construction techniques bring this historic building into the future, with smart home integrations and eco-friendly materials ensuring comfort and sustainability without compromising the building’s classic charm.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Our renovation melds the building’s storied past with the convenience of modern living, carefully preserving ornamental details while updating interiors for a fresh, contemporary lifestyle.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Designed with an eye towards the future, the property’s structure is primed for potential vertical expansions, adding value and possibilities in Brooklyn’s ever-growing real estate landscape.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            Upon stepping into this building, you don't just find a place to live—you discover a narrative woven into the very fabric of its walls. This triplex stands proudly as a testament to the timeless elegance of Brooklyn's architecture, offering an unparalleled opportunity to create memories in a home that's as unique as you are. Don't let this exclusive chance to own a piece of Brooklyn's heritage pass you by.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>As long-term residents of Brooklyn, we were captivated by the property's perfect blend of history and modernity. Living here has given us a sense of connection to the neighborhood's rich past, while the modern touches make our daily life comfortable and luxurious. It's truly the best of both worlds.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS1;