import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS9 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS9';

const ProjectDetailsRS9 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Bay Ridge' />

            <ProjectDetailsContainerRS9 />

        </>
    );
};

export default ProjectDetailsRS9;