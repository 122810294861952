import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS15 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS15';

const ProjectDetailsRS15 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Brownstone Brooklyn' />

            <ProjectDetailsContainerRS15 />

        </>
    );
};

export default ProjectDetailsRS15;