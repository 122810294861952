import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainerHVAC from '../../Containers/ServiceContainers/ServiceContainerHVAC';

const ServiceDetailsHVAC = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='HVAC' heading='HVAC' />
            <ServiceContainerHVAC />
        </>
    );
};

export default ServiceDetailsHVAC;