import $ from 'jquery';
import React, { useEffect } from 'react';
import Sliders from './ImageGallery';


const ProjectDetailsContainerConstFlatbush = () => {
    const nombre = './assets/Flatbush.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Modern Living in Flatbush: An Urban Oasis</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>This beautifully renovated property in the heart of Flatbush, Brooklyn, stands as a testament to urban elegance and modern living. The project aimed to transform this traditional townhouse into a spacious, light-filled home while maintaining its historical charm. Large windows flood the home with natural light, while the open floor plan invites both quiet relaxation and lively entertaining. Every corner has been designed to balance practicality and luxury.</p>

                                        <p>
                                        From the chic hardwood flooring to the sleek, state-of-the-art kitchen, every element has been carefully chosen to provide a seamless living experience. The kitchen features stainless steel appliances, including a top-of-the-line refrigerator and gas range, paired with sophisticated cabinetry and stunning tile backsplashes. The modernized bathrooms boast clean lines and timeless tile work, creating spaces that are both functional and beautiful.
                                        </p>
                                        <p>
                                        Our construction team used cutting-edge technology to maximize the use of space while keeping energy efficiency at the forefront. This renovation brings comfort, style, and contemporary design together to meet the needs of today's city dwellers. Whether it’s the elegant finishings or the carefully designed layout, this Flatbush townhouse now offers a sanctuary of calm in the middle of bustling Brooklyn.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Seamless Design for a Modern Lifestyle</p>
                                        <ul class="point_order">
                                            <li>Sophisticated Finishes: Hardwood floors throughout the home combined with clean, minimalist trim provide a polished aesthetic that complements modern tastes.</li>
                                            <li>State-of-the-Art Kitchen: The kitchen includes top-tier stainless steel appliances, quartz countertops, and custom cabinetry for a sleek and functional workspace.</li>
                                            <li>Energy-Efficient Lighting: Recessed lighting in every room ensures the home is always bright, while using energy-efficient solutions to lower utility costs.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/New_Flatbushave_Brooklyn_1.png" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Flatbushave_Brooklyn_5.png" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Embarking on a journey to revitalize history, our building renovation project is a blend of meticulous craftsmanship and modern functionality. From the polished hardwood floors to the integration of smart home technology, every aspect of the renovation process has been carefully orchestrated to ensure a seamless transition from the past to the present.
                                        </p>
                                        <p>As stewards of architectural integrity, we've meticulously planned every stage of the renovation to preserve the building's historic essence while infusing it with contemporary comfort. Our approach is detailed, deliberate, and dedicated to excellence.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The updated floor plan was re-imagined to maximize both living space and natural light. Open layouts in the living, dining, and kitchen areas promote flow, making the property perfect for entertaining or spending quiet evenings at home. Bedrooms are designed to offer tranquility and privacy, while spacious closets and storage areas ensure functionality is never compromised.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                This project employed the latest in construction and renovation technology. From the high-efficiency HVAC system to energy-efficient windows and advanced insulation materials, every step was taken to ensure the home meets modern environmental standards while maintaining comfort and durability.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The design philosophy behind this renovation was to merge the home’s traditional architecture with contemporary style. The aim was to create spaces that feel timeless, blending modern features with classic materials, ensuring the home remains both current and enduring.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Innovative Solutions for Enhanced Pharmacy Operations</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Each unit is equipped with modern amenities, including a smart thermostat system, updated plumbing, and electrical systems. The kitchen boasts stainless steel appliances, while the bathrooms offer luxurious finishes that combine practicality with sophistication. Additionally, an in-unit washer and dryer provide maximum convenience for city living.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                        This Flatbush townhouse renovation is the perfect fusion of Brooklyn charm and modern living, offering an ideal retreat from the city while being just steps away from the vibrant life of the neighborhood. The home’s careful design and state-of-the-art features create a space that not only looks beautiful but also caters to the needs of today’s homeowners.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>Working with the Skyline City Team on the renovation of our Flatbush home was a fantastic experience. They understood our vision and transformed our townhouse into a modern, yet timeless space. The attention to detail, the use of high-quality materials, and the seamless communication throughout the process made it a breeze. We’re absolutely in love with our new home!</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerConstFlatbush;