import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS16 = () => {
    const nombre = './assets/CrownHeights_BK5.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Crown Heights</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Embracing the timeless grandeur of Crown Heights' historic essence, this meticulously preserved 4-story limestone behemoth stands proudly as a testament to architectural craftsmanship. Built in the year of the first modern Olympics, 1896, by the renowned George P. Chappell, its facade speaks of epochs past yet houses a modern heartbeat within. The owner's duplex unfurls into a lavish expanse, offering a backyard oasis—a serene retreat from the urban exuberance, perfect for contemplative solitude or grand social festivities. Meanwhile, the upper echelons, home to two rental units, promise the same charm to those fortunate enough to reside under this stately roof.</p>

                                        <p>
                                            The property, an 18-foot-wide masterpiece, marries history with functionality, retaining an air of regal dignity while serving contemporary comforts. As you pass through the original wainscoting and beveled mirrors, each step on the noble wood floors echoes the stories of a bygone era. And yet, the modern updates whisper progress—new plumbing, electric, and mechanical systems ensuring a seamless blend of reliability with splendor. With proximity to the cultural tapestry of Franklin Avenue and the effervescent Berg’n Brooklyn, this residence offers not just a home but a piece of history and a gateway to a vibrant community.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>Original period details like the wainscoting and plaster molding exude a regal elegance, harmoniously complemented by modern amenities for a luxurious living experience.</li>
                                            <li>The expansive backyard is a veritable sanctuary, where the green tendrils of nature and serene privacy create an idyllic space for relaxation and entertainment.</li>
                                            <li>Beveled wall mirrors reflect both the rich history and the carefully curated contemporary updates, bridging two worlds with a seamless grace.</li>
                                            <li>The majestic carved wood detail and original doors frame each day with an enduring charm, ensuring that every moment at home feels steeped in luxury.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS19_kitchen.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS19_stairs.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Entering President Street is a step into a realm where the grandiose history of Brooklyn's architecture meets the pinnacle of modern refinement. The service journey begins with a personal consultation to discern the specific desires and requirements of the discerning homeowner. Following this, a detailed walkthrough unveils the intricate craftsmanship and modern upgrades that breathe life into this historic property. The subsequent steps involve a careful inspection of the bespoke renovations, ensuring every inch aligns with both the aesthetic and structural integrity that one would expect from a residence of such stature.
                                        </p>
                                        <p>Post-purchase, the new owners are endowed with a comprehensive guide to the maintenance and enhancement of the property's period features and modern amenities. A dedicated concierge service is provided, offering expert advice on further personalization and upkeep. This ensures that the transition into this stately home is as seamless as the fusion of its original charm with its contemporary enhancements. The journey from spectator to proprietor is a curated experience, tailored to envelop the new residents in luxury and historical opulence.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The residence's layout is a tapestry of thoughtfully delineated spaces. The owner's duplex unfolds with an eloquence of design, featuring a formal reception hall that leads to a living area imbued with natural light. The bedrooms are strategically placed to offer serene views of the sprawling backyard, while the rental units above are self-contained havens of comfort and charm, each offering unique vistas of the neighborhood.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Behind the stately facade lies a triumph of restoration, where old-world charm is underpinned by modern advancements. The property has been rejuvenated with precision, using cutting-edge construction technologies—from updated plumbing to electrical systems—all carefully installed to preserve the property’s historical integrity. The mechanicals and boiler, crucial to the building's performance, have been replaced with units that exemplify efficiency and longevity.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    This restoration project was envisioned as a love letter to Crown Heights' storied past. Every design decision was weighed with reverence for the original architect's intent, ensuring that updates did not overshadow the property's inherent character. The project was executed with a belief that a home should be a sanctuary that honors its lineage while embracing the future.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Investing in President Street is not merely an acquisition of real estate but an embrace of a slice of Brooklyn's history. The property stands as a beacon of the neighborhood's renaissance, with its location in a historic district ensuring enduring allure. The thoughtful upgrades and proximity to key amenities position it as a wise and potentially lucrative investment in a neighborhood where the old and new converge to create a vibrant, sought-after community fabric.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            President Street stands as more than just a residence—it’s a portal to an era of architectural marvels. Meticulously upgraded to meet the exacting standards of modern convenience, while preserving the soulful artistry of its inception, this property isn't merely seen; it's felt.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>From the moment we entered the grand hallway of 1136, we knew we'd found not just a house, but a narrative—a home that tells a story with every intricate detail. The seamless integration of state-of-the-art upgrades with the residence's original splendor made living here an unparalleled experience. The community, rich in culture and vibrancy, welcomed us with open arms, and our backyard became a coveted retreat for friends and family alike. This isn't just our house; it's a legacy we live in every day.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS16;