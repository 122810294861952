import React from 'react';

const Partners01 = () => {
    return (
        <div className="patner">
            <div className="patner_image">
                <img src="images/patner/new_patner/1.png" alt="" />
            </div>
            <div className="patner_image">
                <img src="images/patner/new_patner/2.png" alt="" />
            </div>
            <div className="patner_image">
                <img src="images/patner/new_patner/3.png" alt="" />
            </div>
            <div className="patner_image">
                <img src="images/patner/new_patner/4.png" alt="" />
            </div>
            <div className="patner_image">
                <img src="images/patner/new_patner/5.png" alt="" />
            </div>
            <div className="patner_image">
                <img src="images/patner/new_patner/6.png" alt="" />
            </div>
        </div>
    );
};

export default Partners01;