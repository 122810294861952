import React from 'react';
import BlogCards02 from '../../Components/BlogCards/BlogCard02';
import PageHeader from '../../Components/common/PageHeader';
import Pagination from '../../Components/common/Pagination';


const Blog02 = () => {
    const BlogData02 = [
        {
            id: '1',
            img: 'images/blog/b3.png',
            title: 'Why Commercial Design Can Make or Break Your Business Success',
            route: '/blog-details'
        },
        {
            id: '2',
            img: 'images/blog/b4.png',
            title: 'How to Elevate Your Real Estate Projects with Award-Winning Construction Techniques',
            route: '/blog-details-2'
        },
        {
            id: '3',
            img: 'images/blog/b5.png',
            title: 'Top 10 Electrical Solutions Every Modern Building Needs',
            route: '/blog-details-3'
        },
        {
            id: '4',
            img: 'images/blog/b6.png',
            title: 'How Exterior Work Enhancements Can Boost Your Property’s Value',
            route: '/blog-details-4'
        },
        {
            id: '5',
            img: 'images/blog/b7.png',
            title: '3D Rendering: The Game-Changer in Modern Construction Planning',
            route: '/blog-details-5'
        },
        {
            id: '6',
            img: 'images/blog/b8.png',
            title: 'Why Strategic Construction Consulting Is Essential for Buyers and Renters',
            route: '/blog-details-6'
        },
        {
            id: '7',
            img: 'images/blog/bg-4.png',
            title: 'Top HVAC Innovations That Are Revolutionizing Climate Control in 2024',
            route: '/blog-details-7'
        },
        {
            id: '8',
            img: 'images/blog/bg-3.png',
            title: 'How to Achieve Seamless Electrical Installations in New Constructions',
            route: '/blog-details-8'
        },
        {
            id: '9',
            img: 'images/blog/bg-7.png',
            title: 'The Role of 3D Rendering in Reducing Construction Errors and Costs',
            route: '/blog-details-9'
        },

    ];

    return (
        <div className="main_wrapper">
            <PageHeader activeClass01='active' activeClass02='d-none' routeName01='Blogs' heading='Blogs' />
            <div className="blog section">
                <div className="container">
                    <div className="blog_grid">
                        <div className="row">
                            {
                                BlogData02.map(data => <BlogCards02
                                    key={data.id}
                                    data={data}
                                />)
                            }

                            <Pagination />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog02;