import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS7 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS7';

const ProjectDetailsRS7 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Bedford Stuyvesant' />

            <ProjectDetailsContainerRS7 />

        </>
    );
};

export default ProjectDetailsRS7;