import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerConstFlatbush from '../../Containers/ProjectContainers/ProjectDetailsContainerConstFlatbush';

const ProjectDetailsConstFlatbush = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='House Renovation' />

            <ProjectDetailsContainerConstFlatbush />

        </>
    );
};



export default ProjectDetailsConstFlatbush;





/*
ProjectDetailsReno2 ->ProjectDetailsConstFlatbush
ProjectDetailsContainerConstGreatNeck -> 

*/
