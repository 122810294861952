import React from 'react';
import { Controller, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import TestimonialCard01 from '../../Components/TestimonialCards/TestimonialCard01'

const Testimonial01 = (props) => {
    const TestimonialData01 = [
        {
            id: '1',
            testimonialText: "SkylinecityNY has an exceptional team that pays close attention to what their clients desire. Their work on our office redesign in Manhattan was nothing short of spectacular. The innovative design has breathed new life into our workspace, and the project management was seamless. Their New York spirit of excellence is evident in every detail.",
            img: 'images/reviewer1.png',
            country: 'New York',
            name: 'Jonathan Adler'
        },
        {
            id: '2',
            testimonialText: "The expertise of SkylinecityNY is evident in the skyline of New York itself. Their work on our residential development in Brooklyn has set a new standard for luxury living. The project team delivered on their promise of quality and sophistication. We’re beyond thrilled with the results and the new landmark they've created in our beloved city.",
            img: 'images/reviewer4.png',
            country: 'New York',
            name: 'Marina Mitchell'
        },
        {
            id: '3',
            testimonialText: "SkylinecityNY is synonymous with structural artistry and reliability. Their contribution to our downtown retail complex was monumental. The balance of aesthetic appeal with practical functionality has made our property a hotspot in New York. Their commitment to deadlines and detail-oriented approach sets them apart in the bustling construction landscape of the city.",
            img: 'images/reviewer3.png',
            country: 'New York',
            name: 'John Sirtis'
        },
        {
            id: '4',
            testimonialText: "As a restaurateur in New York, I wanted a space that spoke to the city's vibrant culture. SkylinecityNY delivered a dining space that is both stylish and inviting. Their team worked with our vision and crafted an ambiance that enhances our customers' dining experience. The project was a recipe for success, blending innovation with classic New York grit.",
            img: 'images/reviewer5.png',
            country: 'New York',
            name: 'Michael Lovera'
        },
        {
            id: '5',
            testimonialText: "Choosing SkylinecityNY for our hotel renovation in Queens was the best decision we made. Their team's impeccable taste and understanding of New York's dynamic style resulted in a space that is both modern and timeless. They managed to capture the essence of New York's charm in every room and hallway. Their professionalism and creativity are unmatched.",
            img: 'images/reviewer6.png',
            country: 'New York',
            name: 'Victoria Frakes'
        },

    ]
    return (
        <div className={`testimonial ${props.pb} `}>
            <div className="container">
                <div className="section_header text-center">
                    <div className="shadow_icon"><img src="images/shadow_icon3.png" alt="" /></div>
                    <h6 className="section_sub_title">Clients testimonial</h6>
                    <h1 className="section_title">What our clients say about us</h1>
                    <p className="section_desc">SkylineCityTeam, the best construction company in the tri-state-area.</p>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Swiper
                            className="owl_testimonial1 owl-carousel owl-theme"
                            modules={[Pagination, Controller]}
                            effect={"slide"}
                            breakpoints={{
                                640: {
                                    width: 640,
                                    slidesPerView: 1,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                            }}
                            loop={true}
                            controller={{ inverse: true }}
                            spaceBetween={50}
                            // centeredSlides={true}
                            pagination={{
                                el: '.testimonial-pagination',
                                clickable: true,
                                // dynamicBullets: true,
                            }}
                        >

                            {
                                TestimonialData01.map(data =>
                                    <SwiperSlide>
                                        <TestimonialCard01
                                            key={data.id}
                                            data={data}
                                        /></SwiperSlide>)
                            }


                        </Swiper>

                        {/* <!-- Add Pagination --> */}
                        <div className="testimonial-pagination"></div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial01;