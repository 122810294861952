import React from 'react';

const Slider = () => {
    return (
        <div className="theme_slider_1">
            <div className="slider">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="slide_content">
                                <h5 className="sub_heading">CALL US<span> 1-844-444-4097</span></h5>
                                <h2 className="heading">Where You Find The Best Builders</h2>
                                <p className="desc">Welcome to Skyline City Team – New York's Premier Choice for Safe, Reliable, and Cost-Effective Construction company.At Skyline City Team, we pride ourselves on delivering top-tier construction services tailored to meet your unique needs!</p>
                                <div className="slider_button">
                                    <a href="/about" className="button">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slider;