import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainerExteriorWork from '../../Containers/ServiceContainers/ServiceContainerExteriorWork';

const ServiceDetailsExteriorWork = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Exterior Work' heading='Exterior Work' />
            <ServiceContainerExteriorWork />
        </>
    );
};

export default ServiceDetailsExteriorWork;