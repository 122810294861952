import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS11 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS11';

const ProjectDetailsRS11 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Crown Heights Brooklyn' />

            <ProjectDetailsContainerRS11 />

        </>
    );
};

export default ProjectDetailsRS11;