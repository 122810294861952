import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS8 = () => {
    const nombre = './assets/Bedford_Stuyvesant3.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Bedford Stuyvesant </h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Embedded in the historic junction of Bedford Stuyvesant and Ocean Hill, Radde Place emerges as a captivating prospect for those with an eye for potential and a heart for restoration. This two-family, three-story brick townhouse exudes the charm of yesteryear, combined with the solid foundation of "good bones," a new boiler, and the whispered promise of its marble mantels and original parquet flooring. It's a canvas awaiting the strokes of personal design and modern touches, all within a peaceful street that boasts proximity to key transport links for a swift jaunt to the city's beating heart.</p>

                                        <p>
                                            Upon stepping into Vernon Avenue, the allure of architectural nostalgia greets one's senses. This three-story, two-family brick abode currently offers a single-family setting, standing proudly mere minutes from the J, M, Z, and G subway lines. Its authentic marble mantels, pocket doors, and a well-preserved wooden staircase with an ornate banister speak of a bygone era. With a lot stretching 18x100 feet and an R6B zoning, this property not only presents an expansive 2,235 square feet of living space but also invites further creativity with an additional 1,406 square feet of buildable area.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Refined Details And Luxurious Touches: Both properties boast a wealth of preserved features that exude timeless elegance and offer a unique opportunity to infuse modern luxuries while honoring their historic charm.</p>
                                        <ul class="point_order">
                                            <li>The marble mantels serve as the cornerstone of the townhouse's bygone allure, promising the possibility of becoming the centerpiece of a carefully modernized and stylish interior design.</li>
                                            <li>Original parquet flooring throughout Radde Place whispers stories of the past, offering a warm and rich foundation upon which new memories can be built.</li>
                                            <li>Vernon Avenue's pocket doors and woodwork represent the intricate craftsmanship of its era, providing a character-rich backdrop for a contemporary yet respectful renovation.</li>
                                            <li>Each property’s architectural details, from the moldings to the banisters, present a distinctive canvas, ripe for restoration to its original splendor or for a creative reinterpretation that bridges past and future aesthetics.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS6_inside.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS6_inside1.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Embarking on the journey of transforming either Radde Place or Vernon Avenue into your dream dwelling involves meticulous planning and a step-by-step approach. Initially, our dedicated team will engage with you to outline a vision that respects the property's heritage while incorporating your personal taste and modern necessities. We will conduct a thorough evaluation of the existing structures, identifying areas that call for essential upgrades and areas where the original charm can be preserved and enhanced.
                                        </p>
                                        <p>As we proceed with crafting a living space that resonates with both history and comfort, our attention to detail will ensure that every inch of your future home is tailored to meet your standards of luxury and functionality. From the initial inspection to the final walkthrough, our commitment to excellence will be the guiding principle behind each service step we undertake.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Our in-depth analysis of the existing floor plans will include recommendations for optimizing living spaces, enhancing natural light, and improving flow throughout the house to ensure that every square inch is utilized to its full potential.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    We'll integrate cutting-edge construction technology with traditional methods, ensuring the preservation of the townhouse's historic charm while achieving modern performance and efficiency standards.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Every renovation decision is made with the dual objective of honoring the property's past while crafting a comfortable and sustainable future, balancing aesthetic appeal with environmental consciousness.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    With our finger on the pulse of the latest market trends and zoning regulations, we provide informed insights that maximize your investment and ensure that the property's evolution contributes to the neighborhood's charm and value.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            We were immediately drawn to the historical narrative etched into every corner of Radde Place. The team recognized our desire to maintain that integrity while weaving in our personal story. They respected the property's past, advised us on sustainable upgrades, and managed to transform a structure into our home—a place where future memories will be as cherished as those that the walls have already witnessed. Their expertise in balancing restoration with innovation made all the difference. We couldn't be happier to embark on our new chapter here.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>Stepping into the renovated building was like walking into a perfect marriage of history and modernity. The designers have done a magnificent job preserving the soul of the original structure while providing all the modern comforts we've come to expect. Every morning, I'm greeted by the gleaming original hardwood floors and the sunlight pouring through the large windows, offering a stunning view of the cityscape. The state-of-the-art kitchen and smart home features have made living here not just luxurious but incredibly convenient. It's clear that every detail was thoughtfully considered to create a living experience that's truly second to none. I'm proud to call this my home.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS8;