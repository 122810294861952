import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS14 = () => {
    const nombre = './assets/StuyvesantHeights2.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Stuyvesant Heights</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Immersing oneself in the historical grandeur of this Chateauesque-style residence is akin to stepping into a piece of Brooklyn's heritage, meticulously preserved to echo the lavish lifestyle of late 19th-century elegance. Built in 1892 and distinguished by Daniel Brooks Norris's architectural prowess, this four-story, two-family limestone masterpiece stands as a testament to the era's craftsmanship, boasting intricate foliated carvings and stoic colonnettes that frame its façade with an air of nobility. Within, the home opens up to a tableau of heritage luxury, where every glance reveals another meticulously conserved detail, narrating stories of yesteryear's opulence.</p>

                                        <p>
                                            The home’s generously sized interiors are a harmony of classic charm and modern functionality. The duplex or possible triplex layout for the owner, coupled with a lucrative rental opportunity and a finished cellar, presents a living experience that is as flexible as it is grand. Original features such as beautifully carved doors, a welcoming pier mirror, and multiple fireplace mantles are complemented by contemporary comforts. Steeped in the rich tapestry of Brooklyn's storied past, this dwelling is not just a house but a keepsake of architectural artistry, set amidst the verdant award-winning greenery of Stuyvesant Heights.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>The residence's original woodwork speaks volumes of its storied past. Exquisitely carved doors stand as silent sentinels to history, while the deep mahogany of the fireplace mantles exudes a warmth that is as much about ambiance as it is about actual heat.</li>
                                            <li>Each piece of stained glass in the home scatters colorful reflections across polished hardwood floors, casting a spectrum of light that enlivens the historical narrative of the interiors.</li>
                                            <li>Despite its historic roots, the home does not shy away from modernity. The integration of updated fixtures, central cooling, and sleek lighting solutions ensures a living experience that rivals contemporary luxury, without betraying its Victorian charm.</li>
                                            <li>From the hand-crafted wainscoting to the intricate cast iron wood-burning stove, each architectural feature of this home has been preserved with an attention to detail that transforms everyday living into an experience of historical luxury.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS17_frontplant.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS17_kitchen-4.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            When it comes to the "Service Steps," our commitment to blending historical integrity with modern living begins with a comprehensive approach. The restoration process was undertaken with meticulous care to ensure the preservation of the property's architectural heritage. Specialists in historical renovations were consulted to provide insights into materials and methods that honor the home's past while reinforcing its structure for the future.
                                        </p>
                                        <p>Our team of experts employed advanced construction technologies to reinforce the property's bones, updating the plumbing and electrical systems to meet today's standards of safety and efficiency. Simultaneously, we delicately restored the facade and interior woodwork, ensuring every brushstroke honored the original aesthetic.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The residence's floor plan is a thoughtful curation of space that balances grandeur with intimacy. Each room transitions seamlessly into the next, ensuring a flow that is both practical for modern living and respectful of the property's original design.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Advanced construction technology melded with time-honored techniques underpins the property's transformation. The use of historically accurate materials ensures longevity, while modern amenities provide the comfort expected in contemporary residences.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Each restoration decision was weighed with historical accuracy at the forefront. From salvaging original floorboards to recreating mouldings, the intention was always to echo the property's storied past while ushering it into the future.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The property's renovation was more than a project; it was a revival. The aim was to create a space that did not just resonate with the echoes of history but also met the demands of the current market, ensuring a sound investment for future generations.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            This home is a haven where historical elegance meets modern luxury, offering a living experience that is as enriching as it is exquisite. It is a unique opportunity to own a piece of Brooklyn's legacy, lovingly restored to meet the discerning standards of contemporary living.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>When we first set foot in this historical gem, we were captivated by the level of detail and care that had gone into its restoration. Living here has been a dream realized. It's like owning a piece of history, yet every modern convenience is at our fingertips. Our mornings begin with sunlight filtering through stained glass, and evenings are spent by the fireplace that seems to whisper tales of the past. Our home is more than a space; it's a story, and we are thrilled to be a part of its continuing narrative.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS14;