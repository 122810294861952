import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerRS10 from '../../Containers/ProjectContainers/ProjectDetailsContainerRS10';

const ProjectDetailsRS10 = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Midtown East NY' />

            <ProjectDetailsContainerRS10 />

        </>
    );
};

export default ProjectDetailsRS10;