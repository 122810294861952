import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ProjectDetailsContainerPharm from '../../Containers/ProjectContainers/ProjectDetailsContainerPharm';

const ProjectDetailsPharm = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Projects' routeName02='Projects Details' heading='Pharmacy' />

            <ProjectDetailsContainerPharm />

        </>
    );
};

export default ProjectDetailsPharm;