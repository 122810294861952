import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS18 = () => {
    const nombre = './assets/Bedford.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Bedford Stuyvesant</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Stepping into this Stuyvesant Heights treasure is like stepping back in time—a home where each room tells a story, woven through time with its original woodwork, stained glass windows, and intricate wainscoting. From the graceful entrance through the stately doors, you're invited into a grand entry foyer that leads to an inviting parlor floor. It's here that the home's old-world charm truly shines, with its decorative fireplace beckoning family and friends to gather around its warmth.</p>

                                        <p>
                                            This two-family brick townhouse, sprawling across a generous lot, offers a rare combination of historical allure and modern convenience. The carefully preserved details—a testament to its 1899 roots—create a canvas for living a life enriched by history. Yet, the modern mechanics, including a newly installed boiler, ensure comfort and ease. Each floor echoes the same blend of heritage and heart, from the massive 2.5 bedroom floor-through on the parlor level to the bright, airy bedrooms above.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>The decorative fireplace, set against a backdrop of mosaic tiling, not only adds warmth but serves as a focal point for the living room, exuding the elegance of the era in which the home was built.</li>
                                            <li>Original pocket doors slide open to reveal expansive living spaces, their rich wood a reminder of the home's storied past and offering a sense of privacy and grandeur.</li>
                                            <li>Stained glass windows scatter colorful light across polished parquet floors, imbuing the space with an ambience that is both ethereal and timeless.</li>
                                            <li>The staircase, a piece of art in its own right, boasts intricate carvings that guide you through the home, hinting at the craftsmanship and attention to detail that has been preserved over a century.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS23_fireplace.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Rs23_living-room.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Understanding a home's history is key to appreciating its value. Here, every feature—from the architecture to the original fixtures—has been meticulously maintained, ensuring a seamless blend of antique charm and modern functionality. Our service begins with a thorough walkthrough, allowing potential buyers to experience firsthand the craftsmanship of the late 19th century, followed by a detailed overview of the home's updated systems for peace of mind.
                                        </p>
                                        <p>Our comprehensive approach to showcasing this home includes a meticulous inspection, ensuring that every historic detail is highlighted, every modern upgrade is documented, and every potential for future customization is explored. We delve deep into the property's past to uncover its stories, which we then share with you to forge a connection that goes beyond the physical structure to the heart of what makes this house a home.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The parlor level's open floor plan encourages social gatherings, while the upper levels promise privacy and repose. The semi-finished basement offers a canvas for further personalization, a space where imagination can bring new life.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The sturdy brick facade stands as a testament to the era's building prowess, while the updated mechanicals inside assure modern-day comfort. The home's structural integrity has been preserved through thoughtful maintenance and timely upgrades.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Respect for the past drove the preservation efforts, ensuring that while the home met contemporary standards, its historical soul remained untouched. The transformation from a single to a dual-family residence was executed with an emphasis on retaining its original character.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    This property illustrates the value of historical charm in today's market. It represents a unique investment opportunity: a home with an unshakeable foundation in history and an eye towards future potential.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            In wrapping up the tale of this historic gem, one cannot help but be moved by the care and dedication that have preserved its character. This is a home that doesn't just stand in Stuyvesant Heights; it stands for something—a piece of history, a bastion of beauty, and a testament to the timeless appeal of truly loving where you live.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>When we first laid eyes on this Stuyvesant Heights classic, we knew we had found something special. The seamless melding of historic craftsmanship with the comforts of modern living made this more than just a house—it became our sanctuary. The preserved details, like the stained glass windows and original woodwork, spoke to us in a way no new construction ever could. Living here has been a dream, with every creak of the floorboards whispering the stories of a bygone era. We couldn't have asked for a more soulful place to call home.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS18;