import $ from 'jquery';
import React, { useEffect } from 'react';
import Sliders from './ImageGallery';

const ProjectDetailsContainerConstHerkimer = () => {
    const nombre = './assets/Herkimer.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Herkimer Street Brownstone Revival: A Modern Masterpiece</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Nestled in the heart of Brooklyn, this Herkimer Street brownstone has undergone an extensive restoration, turning a classic architectural gem into a modern-day masterpiece. The Skyline City team carefully balanced the old-world charm of this historic property with state-of-the-art upgrades to create a living space that meets the demands of modern homeowners. From the street, the brownstone retains its original appeal with a facade that has been meticulously preserved, celebrating the craftsmanship of an earlier era while subtly hinting at the modern comforts within.</p>
                                        <p>Step inside, and you are immediately struck by the seamless blend of past and present. The grand entrance, accented with restored woodwork and traditional moldings, opens into a space reimagined for modern living. Expansive open-concept floor plans allow for natural light to flood the home, enhancing the sense of space and tranquility. Every room has been thoughtfully redesigned to offer a perfect blend of form and function. Whether you're enjoying the sleek, high-end kitchen or relaxing in the spa-inspired bathroom, the modern touches effortlessly complement the home’s timeless elegance.</p>
                                        <p>This project showcases a dedication to preserving the past while embracing the future. Original features such as the intricate staircase, the bay windows, and classic fireplaces have been expertly restored, ensuring that the home retains its unique character. However, beneath the surface, modern systems including energy-efficient HVAC, smart lighting, and integrated security ensure that the property is as practical as it is beautiful. The result is a home that honors its rich history while providing all the luxuries and conveniences required for contemporary urban living.</p>
                                        <h4 class="widget_title">
                                            Sleek and Functional Design
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">A Harmonious Blend of Tradition and Modernity</p>
                                        <ul class="point_order">
                                            <li>Preserving the Brownstone’s Historic Essence: Skyline City took special care in preserving key architectural elements, such as original woodwork, moldings, and facade details, while integrating them seamlessly with modern design aesthetics. These details help to maintain the home's historical integrity while providing an updated living experience.</li>
                                            <li>Thoughtfully Designed Modern Spaces: The project’s interior layout reflects a commitment to modern living with open floor plans that create a sense of flow between spaces. Spacious kitchens are fitted with top-tier appliances and sleek, minimalistic finishes, while the living areas are designed for versatility and comfort, offering both formal and informal spaces for entertaining or family life.</li>
                                            <li>Modern Technology Meets Classic Architecture: Behind the charming exterior lies a host of modern amenities. From smart thermostats and lighting controls to high-efficiency heating and cooling systems, the renovation blends 21st-century technology into the bones of this historic property without sacrificing its aesthetic appeal.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Herkimer_36.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Herkimer_21.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Embarking on this house renovation, we were inspired to create a living space that resonates with both the charm of yesteryears and the convenience of modern-day amenities. Our commitment was to ensure that each space within the home was both functional and aesthetically pleasing.
                                        </p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The newly designed floor plan strikes the perfect balance between maintaining the classic brownstone's charm and introducing modern, open living spaces. Upon entering, you're greeted by an open-concept living room and kitchen space that maximizes light and air, creating a welcoming atmosphere. Each bedroom is spacious, with high ceilings and large windows that invite natural light. The bathrooms are retreats in themselves, featuring clean lines, luxury fixtures, and a calming color palette that promotes relaxation. The multi-level design ensures privacy while providing ample room for both intimate family time and entertaining guests.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                Skyline City’s approach to this renovation relied heavily on cutting-edge construction technologies to enhance both comfort and sustainability. From start to finish, advanced materials and techniques were used to improve energy efficiency, including high-performance insulation, energy-efficient windows, and smart HVAC systems that ensure the home remains comfortable year-round while reducing energy costs. The home was also outfitted with integrated smart-home technology, allowing for automated control of lighting, temperature, and security from anywhere in the world through a smartphone or tablet.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Design Philosophy</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                The design philosophy behind this renovation was deeply rooted in maintaining the architectural integrity of the original brownstone while creating a home suited for modern living. Skyline City worked diligently to preserve key elements that give brownstones their iconic status, from the stately bay windows to the ornate staircases, blending these features with modern materials and finishes that enhance, rather than detract from, their beauty. Every detail, from the layout to the choice of materials, was selected to create a space that feels both timeless and contemporary. By blending classic and modern aesthetics, this home offers the best of both worlds.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Modern Amenities for Today's Lifestyle</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                While the exterior and historic features of this brownstone have been lovingly restored, the interior offers the very latest in modern conveniences. Kitchens are equipped with stainless steel appliances, marble countertops, and custom cabinetry, making it a chef’s dream. Bathrooms include rain showers, heated flooring, and spa-inspired features for a relaxing retreat at the end of the day. The smart home system allows the homeowner to control lighting, security, and climate remotely, creating a space that’s as convenient as it is luxurious. Whether it’s a quiet evening at home or hosting a gathering, the modern amenities ensure that every need is met with sophistication and ease.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                        The restoration of this Herkimer Street brownstone is more than just a renovation; it’s a revitalization of history. Skyline City’s meticulous attention to detail has transformed this property into a home that effortlessly combines Brooklyn’s rich architectural heritage with the conveniences and aesthetics of modern living. From its carefully restored exterior to the modern, luxurious interior, this home is the perfect example of how old-world charm can coexist with contemporary luxury. Every room tells a story of its past while embracing the possibilities of its future, creating a truly unique space that will be cherished for years to come.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>Working with Skyline City has been a dream come true. They took our vision and created something even better than we could have imagined. The way they blended the old and new is just stunning—our brownstone feels like it has a new lease on life, and we couldn’t be happier. The quality of their work and their attention to detail is unmatched. We felt involved and supported every step of the way. This is truly our dream home!</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>House</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Two Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerConstHerkimer;