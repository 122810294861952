import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS9 = () => {
    const nombre = './assets/Bayridge.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Bay Ridge Brooklyn</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Located in the midst of the dynamic and historic Bay Ridge neighborhood, the Park View Building offers a sun-drenched one-bedroom cooperative that exudes the charm of Brooklyn life. This property, located across from the verdant expanse of Cannon Ball Park and a stone's throw from the picturesque Shore Road Promenade, is an urban paradise that provides both peace and accessibility. The apartment, located on the third level of a corner building, is roughly 600 square feet in size and boasts tall ceilings and an inviting layout that maximizes space and natural light.</p>

                                        <p>
                                            This home stands out for its unique position, boasting unobstructed views of the iconic Verrazano Bridge from large windows that welcome sunlight throughout the day. The seamless blend of high ceilings, a generous bedroom with ample closet space, and warm hardwood floors create an atmosphere of elevated comfort, ideal for both relaxation and entertaining.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>Expansive windows frame the impressive Verrazano Bridge, offering not just a view but a backdrop to your daily life.</li>
                                            <li>The semi-open kitchen, complete with modern appliances, blends functionality with a touch of elegance, making meal preparation a delight.</li>
                                            <li>Original hardwood flooring throughout the apartment adds warmth and character, complementing the high ceilings that give an airy, spacious feel.</li>
                                            <li>Storage is both ample and discreet, with two private storage facilities included, ensuring that your living space remains uncluttered and serene.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS10_bedroom.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/Rs10_living-roon1.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            Embarking on your journey to ownership in the Park View Building begins with a personal consultation to understand your desires and requirements. A thorough walk-through will then showcase the apartment's potential, allowing you to envision the space as your future home. Our team is committed to providing guidance throughout the purchase process, from financial advisement to the final closing details.
                                        </p>
                                        <p>With respect to renovations and customizations, our service extends to connect you with trusted contractors and designers who can help translate your vision into reality. We offer support in navigating zoning regulations, ensuring that any modifications are both compliant and enhance the value of your home.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The floor plan reveals a thoughtful layout that maximizes flow and functionality. The entrance foyer leads into a welcoming living area, with the adjacent semi-open kitchen positioned perfectly for both cooking and socializing. The bedroom is a private retreat, strategically placed to afford quiet and rest.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Constructed with enduring materials, the building stands as a testament to timeless craftsmanship. The cooperative has been maintained with an eye towards both preserving its architectural integrity and incorporating modern conveniences for today's homeowner.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The design and maintenance of the Park View Building reflect a commitment to preserving the historical essence of Bay Ridge while meeting the contemporary needs of its residents. It represents a perfect blend of past and present, a place where history is honored and the future is welcomed.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    The Bay Ridge area continues to flourish, with the Park View Building at the forefront of desirable properties. Its location and structural potential represent a smart investment in a neighborhood celebrated for its community spirit and urban conveniences.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            The Park View Building's one-bedroom cooperative is a gem in Bay Ridge, offering a blend of historical charm and modern living. Its proximity to parks, transportation, and local amenities makes it an ideal choice for those starting their homeownership journey in New York City.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>As a first-time homebuyer, the prospect was daunting, but the moment I stepped into this apartment, I felt at home. The view of the Verrazano Bridge is stunning, and the convenience of local parks and transit is unmatched. The team's guidance made the process seamless, and I am thrilled to start my life in this charming neighborhood. Bay Ridge is a treasure, and I feel fortunate to call it home</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS9;