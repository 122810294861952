import $ from 'jquery';
import React, { useEffect } from 'react';
import Video from '../../Components/BannerElements/Video';
import Sliders from './ImageGallery';

const ProjectDetailsContainerRS11 = () => {
    const nombre = './assets/CrownHeights_Bk4.json'
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);
    return (
        <div class="main_wrapper">
            <div class="container">
                <div class="project_details section">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="project_details_inner">
                                <div class="post_img">
                                    <Sliders nombre={nombre} />
                                </div>
                                <div class="post_content">
                                    <div class="post_header">
                                        <h3 class="post_title">Crown Heights Brooklyn</h3>
                                    </div>
                                    <div class="fulltext">
                                        <p>Located in the heart of Crown Heights, this legal four-family townhouse is a jewel with an abundance of promise and flexibility. With its classic red-brick facade and large windows inviting natural light into every corner, this 3280 square-foot property blends timeless architecture with potential modern charm. Its semi-detached design, paired with a substantial 40 x 127-foot lot, offers spacious living units and the rare luxury of a private three-car garage in New York's bustling urban landscape.</p>

                                        <p>
                                            The building presents a canvas for transformation, whether for an investor eyeing the lucrative rental market or a homebuyer dreaming of a rent-free life while earning passive income. Its current fair condition is an open invitation to reinvent, with each apartment—three 2-bedroom and one 3-bedroom layouts—whispering the possibility of becoming a bespoke urban retreat. Its proximity to the Utica subway station enhances its allure, promising easy access to the city's heartbeat.
                                        </p>

                                        <h4 class="widget_title">
                                            Refined Details and Luxurious Touches
                                            <span class="title_line"></span>
                                        </h4>
                                        <p class="margin_o_para">Tailored Spaces for Personalized Living Spaces</p>
                                        <ul class="point_order">
                                            <li>The townhouse’s original hardwood floors and detailed wainscoting speak of its storied past, awaiting a polish to restore their former glory.</li>
                                            <li>Each unit's generous windows offer an infusion of sunlight, illuminating the potential for a warm, welcoming interior that residents will adore.</li>
                                            <li>The sizable lot comes with the potential to duplex units with the finished basement, expanding living spaces and adding a touch of modern luxury with renovations.</li>
                                            <li>The additional air rights hint at the potential for upward expansion, opening up opportunities for rooftop gardens or terraces that could offer serene city views.</li>
                                        </ul>

                                        <div class="post_gallery">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS14_ktch.jpg" alt="img" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <img src="images/projects/RS14_lvnrmback.jpg" alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 class="widget_title">
                                            Service Steps
                                            <span class="title_line"></span>
                                        </h4>
                                        <p>
                                            The journey to transform this Crown Heights gem into a lucrative investment or a dream home begins with a detailed assessment of its current structure and the envisioning of its future state. An initial walkthrough will determine the extent of the renovations required, with a particular focus on the kitchens and baths whose upgrades could significantly elevate the property's appeal.
                                        </p>
                                        <p>Strategic planning is crucial in aligning the renovation process with the ultimate goal, be it maximizing rental income or crafting luxurious owner-occupied units. The existing three vacancies provide an immediate opportunity to begin renovations, while discussions with the current tenant can pave the way for a smooth transition to a fully vacant building at closing, should that be the buyer's wish.</p>
                                        <div class="accordion">
                                            <div class="item active">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Floor Plan Descriptions</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Maximizing Space and Comfort": Each floor plan is a testament to thoughtful urban design, capturing the essence of city living while offering ample room for personalization. The potential for unit duplexing with the finished basement offers an exciting possibility for added luxury and spatial ingenuity.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Construction Technology Used</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    "A Vision for Community and Legacy": Here, we delve into the philosophy driving the renovation, aimed at fostering a strong sense of community while ensuring the longevity of the property's value. The vision combines respect for the property’s historical significance with a forward-looking approach to urban living.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Thoughts Behind the projects</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Dive into a deep analysis of how the utilization of air rights and strategic renovations can substantially increase the property's market value. This narrative explores the intersection of architectural potential and market dynamics that could see this townhouse transform into a flagship model for residential redevelopment in Crown Heights.
                                                </div>
                                            </div>

                                            <div class="item">
                                                <div class="accordion_tab">
                                                    <h2 class="accordion_title">Real Estate Development Insights</h2>
                                                    <span class="accordion_tab_icon">
                                                        <i class="open_icon ion-ios-arrow-down"></i>
                                                        <i class="close_icon ion-ios-arrow-up"></i>
                                                    </span>
                                                </div>
                                                <div class="accordion_info">
                                                    Dive into a deep analysis of how the utilization of air rights and strategic renovations can substantially increase the property's market value. This narrative explores the intersection of architectural potential and market dynamics that could see this townhouse transform into a flagship model for residential redevelopment in Crown Heights.
                                                </div>
                                            </div>
                                        </div>

                                        <p>
                                            The Crown Heights townhouse is not just a building; it's a vision waiting to be realized. With its substantial space, inherent charm, and unmatched potential, this property promises to be more than a home—it's an opportunity to create something truly special in one of Brooklyn's most vibrant neighborhoods. Whether the new owner chooses to harness its rental potential, create luxurious private living spaces, or explore its development possibilities, this townhouse stands as a beacon of potential in the heart of New York.
                                        </p>

                                        <h4 class="widget_title">
                                            Clients Testimonial
                                            <span class="title_line"></span>
                                        </h4>

                                        <div class="testibox1">
                                            <div class="testibox_inner">
                                                <div class="testi-content">
                                                    <ul>
                                                        <li class="text">Rating:</li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <p>As an investor in New York real estate for over a decade, finding a property with such potential is like striking gold. The spacious layout, the possibility of duplexing units, and the air rights for future expansion make this Crown Heights townhouse a rare find. Since purchasing, I've seen my vision come to life, and the returns have exceeded all expectations. It's a property that keeps on giving, and the community has been incredibly welcoming. Truly, this is one investment I'm proud to say will stand the test of time.</p>
                                                </div>
                                                <div class="testi-top">
                                                    <div class="testi-img">
                                                        <img src="images/reviewer1.png" alt="" />
                                                    </div>
                                                    <div class="testi-info">
                                                        <h4>Johnathon Adler</h4>
                                                        <h6>New York</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="share_tag">
                                            <div class="post_footer">
                                                <div class="post_share">
                                                    <ul class="share_list new_share">
                                                        <li>Share:</li>
                                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                        <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                        <li class="instagram"><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                        <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="tags-1" class="widget widget_tag_cloud">
                                                <div class="sidebar_tags">
                                                    <ul class="tag_list">
                                                        <li><a href="about.html">Builder</a></li>
                                                        <li><a href="services.html">Trendy</a></li>
                                                        <li><a href="project.html">Tees</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner_posts">
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p1.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-1.html">Previous Post</a>
                                                    <h4><a href="#">NewYork Apertment</a></h4>
                                                </div>
                                            </div>
                                            <div class="inner-post prev_post">
                                                <img src="images/blog/p2.png" alt="img" />
                                                <div class="post_block">
                                                    <a class="link_to" href="blog-2.html">Next Post</a>
                                                    <h4><a href="#">Under Construction</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="sidebar">
                                <div class="project_info">
                                    <div class="project_info_bg">
                                        <div class="project_info_header">
                                            <h4>Project Information</h4>
                                        </div>
                                        <div class="project_info_details_bg">
                                            <div class="project_info_details">
                                                <h5>Client Name</h5>
                                                <p>N/A</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Location</h5>
                                                <p>New York NY</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Renovation</h5>
                                                <p>Building</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Solving Time</h5>
                                                <p>Three Weeks</p>
                                            </div>
                                            <div class="project_info_details">
                                                <h5>Client Rating</h5>
                                                <ul><li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetailsContainerRS11;