import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainerDemolition from '../../Containers/ServiceContainers/ServiceContainerDemolition';

const ServiceDetailsRealEstate = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Demolition' heading='Demolition' />
            <ServiceContainerDemolition />
        </>
    );
};

export default ServiceDetailsRealEstate;