import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainerPlumbing from '../../Containers/ServiceContainers/ServiceContainerPlumbing';

const ServiceDetailsPlumbing = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Plumbing' heading='Plumbing' />
            <ServiceContainerPlumbing />
        </>
    );
};

export default ServiceDetailsPlumbing;